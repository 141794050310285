import axios, { AxiosResponse } from "axios";
import getEnv from "utils/Envs";
import { headerWithJWT } from "utils/Axios";

export const emailApi = axios.create({
  baseURL: getEnv("REACT_APP_EMAIL_API"),
});

emailApi.interceptors.request.use((config) => {
  config.headers = headerWithJWT().headers;
  return config;
});

export const sendEmail = (
  dataAssetUuid,
  dataAssetName,
  recipientUuid,
  recipientName,
  emailAddress,
  requestedBy
): Promise<AxiosResponse> => {
  return emailApi.post("/register", {
    dataAssetUuid,
    dataAssetName,
    recipientUuid,
    recipientName,
    emailAddress,
    requestedBy,
    sendEmail: true,
  });
};
