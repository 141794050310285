import { gql } from "@apollo/client";

export const GET_TABLE_INFO = gql`
  query getTableInfo($uuid: String!, $database: String!) {
    tableInfo(uuid: $uuid, database: $database) {
      DatabaseName
      Name
      ColumnName
      ColumnType
      RecordCount
    }
  }
`;
