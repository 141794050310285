import { useCallback, useEffect } from "react";

export const useEventListener = ({
  type,
  callback,
  options = false,
  target = document,
  shouldAddEventListener = true,
}) => {
  useEffect(() => {
    if (shouldAddEventListener) {
      target.addEventListener(type, callback, options);
    }

    return () => target.removeEventListener(type, callback);
  }, [type, callback, options, target, shouldAddEventListener]);
};

export const useClickOutside = (
  callback,
  element,
  shouldAddEventListener = true
) => {
  const handleClickOutside = useCallback(
    (event) => {
      if (element && !element.contains(event.target)) {
        callback(event);
      }
    },
    [callback, element]
  );

  useEventListener({
    type: "click",
    callback: handleClickOutside,
    options: true,
    shouldAddEventListener,
  });

  return element;
};
