import React, { useContext } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useQuery } from "@apollo/client";
import { GET_DATA_ASSETS } from "graphql/data";
import {
  PlaceholderText,
  CustomBootstrapTable,
} from "presentational-components";
import { makeTimeStamp } from "utils/TimeStamps";
import { SHARED, SHARED_DATA_ASSET, STAGED } from "../constants";
import { statusFormatter } from "../formatters";
import styles from "./MyData.module.scss";
import { CollabContext } from "../../Navigation/CollabContext";
import classnames from "classnames";
import Upload from "../../DataUploader";

const MyData = () => {
  const collabContext = useContext(CollabContext);
  const currentCollaborationId = collabContext?.currentCollaborationId;

  const {
    data: assetsData,
    loading: assetsLoading,
    error: assetsError,
  } = useQuery(GET_DATA_ASSETS, {
    skip: !currentCollaborationId,
    variables: {
      status: [STAGED, SHARED],
      dataAssetType: SHARED_DATA_ASSET,
      collaborationUuid: currentCollaborationId,
    },
    fetchPolicy: "network-only",
  });

  const formatData = (allData) => {
    if (!Array.isArray(allData)) {
      return [];
    }
    return allData.map(
      ({
        uuid,
        name,
        ownerType,
        owner,
        pointOfContact,
        dateModified,
        status,
        schema,
      }) => {
        return {
          uuid,
          name,
          ownerType,
          owner,
          pointOfContact,
          dateModified,
          ownerName: owner?.name,
          contactName: pointOfContact
            ? `${pointOfContact?.givenName} ${pointOfContact?.familyName}`
            : "",
          status,
          schema,
        };
      }
    );
  };

  let formattedData = [];

  if (!assetsLoading) {
    formattedData = formatData(assetsData?.dataAssets?.dataAssets, assetsError);
  }

  const textTruncateClass = "text-truncate";

  const columns = [
    {
      dataField: "uuid",
      hidden: true,
    },
    {
      dataField: "name",
      text: "Resource Name",
      classes: classnames(textTruncateClass, styles.name),
      headerClasses: textTruncateClass,
      sort: true,
      multiSelectFilter: true,
      headerStyle: () => {
        return { width: "350px", textAlign: "center" };
      },
    },
    {
      dataField: "ownerName",
      text: "Owner",
      classes: textTruncateClass,
      headerClasses: textTruncateClass,
      sort: true,
      multiSelectFilter: true,
    },
    {
      dataField: "status",
      text: "Status",
      classes: textTruncateClass,
      headerClasses: textTruncateClass,
      formatter: statusFormatter,
      sort: true,
      multiSelectFilter: true,
      headerStyle: () => {
        return { width: "140px", textAlign: "center" };
      },
    },
    {
      dataField: "dateModified",
      text: "Last Updated",
      classes: textTruncateClass,
      headerClasses: textTruncateClass,
      sort: true,
      formatter: (cell) => makeTimeStamp(cell, true),
    },
    {
      dataField: "contactName",
      text: "Contact",
      classes: textTruncateClass,
      headerClasses: textTruncateClass,
      sort: true,
      multiSelectFilter: true,
    },
    {
      dataField: "schema",
      formatter: (cell, { schema, uuid }) => {
        return (
          <div className="flex align-items-center">
            <Upload schemaName={schema?.name} dataAssetId={uuid} />
          </div>
        );
      },
      headerStyle: () => {
        return { width: "180px", textAlign: "center" };
      },
    },
  ];

  const getTableLoadingMessage = (loading, error) => {
    if (loading) {
      return <PlaceholderText>Loading...</PlaceholderText>;
    } else if (error) {
      return (
        <PlaceholderText>
          Error fetching resources. Please refresh the page to try again.
        </PlaceholderText>
      );
    } else {
      return <PlaceholderText>No data found.</PlaceholderText>;
    }
  };

  return (
    <CustomBootstrapTable
      keyField="uuid"
      data={formattedData}
      columns={columns}
      noDataIndication={getTableLoadingMessage(assetsLoading, assetsError)}
    />
  );
};

export default MyData;
