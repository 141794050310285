import { gql } from "@apollo/client";

export const GET_DATA_ASSET_DOWNLOAD_INFO = gql`
  query getDataAsset(
    $dataAssetType: String!
    $organizationUuid: String
    $dataAssetUuid: String
    $collaborationUuid: String
  ) {
    dataAsset(
      dataAssetType: $dataAssetType
      organizationUuid: $organizationUuid
      dataAssetUuid: $dataAssetUuid
      collaborationUuid: $collaborationUuid
    ) {
      uuid
      downloadUrl
      totalDownloadCount
    }
  }
`;

export const GET_DATA_ASSETS_DOWNLOAD_INFO = gql`
  query getDataAssets($dataAssetType: String!, $collaborationUuid: String) {
    dataAssets(
      dataAssetType: $dataAssetType
      collaborationUuid: $collaborationUuid
    ) {
      uuid
      downloadUrl
      totalDownloadCount
    }
  }
`;

export const UPDATE_DOWNLOAD_COUNT = gql`
  mutation downloadDataAsset(
    $dataAssetType: String
    $collaborationUuid: String
    $organizationUuid: String
    $dataAssetUuid: String
  ) {
    downloadDataAsset(
      dataAssetType: $dataAssetType
      collaborationUuid: $collaborationUuid
      organizationUuid: $organizationUuid
      dataAssetUuid: $dataAssetUuid
    ) {
      success
    }
  }
`;
