import React, { useState } from "react";
import classNames from "classnames";
import md5 from "md5-hash";
import _ from "lodash";

import styles from "./ProfileAvatarIcon.module.scss";
import { generateGradientColors } from "utils/Profile";

interface Props {
  email: string;
  firstname: string;
  lastname: string;
  className?: string;
  id: string;
}

const ProfileAvatarIcon = ({
  email,
  firstname,
  lastname,
  className: propClassName,
  id,
  ...props
}: Props) => {
  const [doesntHaveGravatar, setDoesntHaveGravatar] = useState(false);

  const createGravatarUrl = () => {
    if (!email) {
      return "";
    }

    let hashedEmail = "";

    hashedEmail = md5(email);

    return `https://www.gravatar.com/avatar/${hashedEmail}?s=350&d=404`;
  };

  const generateGradientColor = _.memoize(generateGradientColors);

  const gradientColor = generateGradientColor("009e83", "#FFFFFF", 10);

  const onError = () => {
    setDoesntHaveGravatar(true);
  };

  const className = classNames(styles.avatar, propClassName);

  return doesntHaveGravatar ? (
    <div
      style={{
        border: `solid 0.25rem 009e83`,
        backgroundColor: `#${gradientColor[8]}`,
      }}
      className={className}
      {...props}
    >
      {`${(firstname && firstname[0]) || ""}${
        (lastname && lastname[0]) || ""
      }`.toUpperCase()}
    </div>
  ) : (
    <img
      {...props}
      className={className}
      src={createGravatarUrl()}
      alt={`gravatar-${firstname}-${lastname}`}
      onError={onError}
    />
  );
};

export default ProfileAvatarIcon;
