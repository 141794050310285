import { IconButton } from "presentational-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import React, { useState } from "react";
import styles from "./CustomSearch.module.scss";

const CustomSearch = ({ onSearch }) => {
  const [searchText, setSearchText] = useState("");

  const onClear = () => {
    setSearchText("");
    onSearch("");
  };

  const onKeyPress = (e) => {
    if (e.code === "Enter") {
      e.preventDefault();
      onSearch(searchText);
    }
  };

  return (
    <div className="d-flex">
      <div className={styles.searchInputWrapper}>
        <input
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText}
          className={styles.searchBar}
          onKeyPress={onKeyPress}
          placeholder="Search"
          aria-label="table-search-input"
        />
        <IconButton
          ariaLabel="Clear Search"
          className={styles.iconButton}
          onClick={onClear}
          icon={<FontAwesomeIcon icon={faTimes} />}
        />
      </div>
      <Button
        onClick={() => onSearch(searchText)}
        className={styles.searchButton}
        aria-label="Search Button"
      >
        Search
      </Button>
    </div>
  );
};

export default CustomSearch;
