import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

import { removeAuthInfo } from "../../store/AuthServer/actionCreators";

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("idToken");
    dispatch(removeAuthInfo());
  }, [dispatch]);
  return <Redirect to="/login" />;
};

export default Logout;
