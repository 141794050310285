import React from "react";
import classNames from "classnames";
import { Handle } from "react-flow-renderer";
import ReactTooltip from "react-tooltip";

import styles from "./CollabFlowNode.module.scss";

export enum CollabFlowNodeType {
  ORGANIZATION = "organization",
  SHARED_DATA = "sharedData",
  THIRD_PARTY = "thirdParties",
  DESTINATION = "destination",
  COMBINED_DATA = "combinedData",
}

export enum CollabFlowNodeOwnerType {
  USER_ORG = "userOrg",
  MEMBER = "member",
  THIRD_PARTY = "thirdParty",
  COLLABORATION = "collaboration",
}

type OrgOrDataResourceNode = {
  id: string;
  data: {
    type:
      | CollabFlowNodeType.ORGANIZATION
      | CollabFlowNodeType.SHARED_DATA
      | CollabFlowNodeType.COMBINED_DATA;
    owner: CollabFlowNodeOwnerType;
    name: string;
    highlighted: boolean;
  };
};

type UseCaseNode = {
  id: string;
  data: {
    type: CollabFlowNodeType.DESTINATION;
    name: string;
    highlighted: boolean;
    owner: undefined;
  };
};

type Props = OrgOrDataResourceNode | UseCaseNode;

/**
 * Custom node component for the React Flow library used in the collaboration map. Pass data through the data field of the element definition
 * The prop types are not well defined by the storybook prop docs since they use TS function overloads.
 * type OrgOrDataResourceNode = {
  id: string;
  data: {
    type: "organization" | "orgDataResource" | "collabDataResource";
    owner: "userOrg" | "member" | "thirdParty" | 'collaboration';
    name: string;
    highlighted: boolean;
};
};

type UseCaseNode = {
  id: string;
  data: {
    type: "useCase";
    name: string;
    highlighted: boolean;
};
};
 */
function CollabFlowNode(props: OrgOrDataResourceNode): React.ReactElement;
function CollabFlowNode(props: UseCaseNode): React.ReactElement;

function CollabFlowNode({
  id,
  data: { type, name, highlighted, ...dataProps },
}: Props): React.ReactElement {
  let styleClass = dataProps.owner !== undefined ? dataProps.owner : type;
  const borderColorClassName = styles[styleClass];

  return (
    <>
      <div
        role="button" // Using a button element introduces a weird bug with react-tooltip
        className={classNames(styles.container, borderColorClassName, {
          [styles.highlighted]: highlighted,
        })}
        data-for={id}
        data-tip={name}
      >
        <div className={styles.label}>{name}</div>
        {type !== CollabFlowNodeType.ORGANIZATION && (
          <Handle className={styles.handle} type="target" position="left" />
        )}
        {type !== CollabFlowNodeType.DESTINATION && (
          <Handle className={styles.handle} type="source" position="right" />
        )}
      </div>
      <ReactTooltip
        backgroundColor="#54575a"
        id={id}
        effect="solid"
        delayShow={100}
      />
    </>
  );
}

export default CollabFlowNode;
