import * as actionTypes from "./actionTypes";

const initialState = {
  errorObj: {},
};

const reducer = (state = initialState, action) => {
  let errorObjCopy;
  switch (action.type) {
    case actionTypes.SHOW_ERROR:
      /**
       * The `errorObj` contains a nested object, wherein
       * the keys contain the ActionType, one of: SET_USERS, SET_CLIENTS,
       * SET_ROLES, SET_SPECIFIED_ROLES, and SET_USER. (The corresponding ActionType facilitates
       * removing the key from the `errorObj` whenever the HTTP request succeeds.)
       *
       * The values contain the Axios error object.
       */
      return {
        ...state,
        errorObj: {
          ...state.errorObj,
          ...action.errorObj,
        },
      };
    case actionTypes.UPDATE_ERROR:
      errorObjCopy = { ...state.errorObj };
      delete errorObjCopy[action.errorToRemove];
      return {
        ...state,
        errorObj: {
          ...errorObjCopy,
        },
      };

    default:
      return state;
  }
};

export default reducer;
