import jwtDecode from "jwt-decode";
import * as ActionTypes from "./actionTypes";
import { errorObj } from "../ErrorHandler/actionCreators";

/**
 * These action creators primarily handle GET requests (e.g., to fetch data from
 * the Authserver for the Users and Clients list views).
 *
 * The functions that make the HTTP requests catch errors (because "...something may happen! You never know what.")
 * After catching an error, the function dispatches the error (via the `errorObj` action).
 * We store this error in global state, and we remove it, if the HTTP request succeeds (see: reducer.js).
 */
export const setAccessTokenInfo = (accessTokenInfo) => {
  return {
    type: ActionTypes.SET_ACCESS_TOKEN_INFO,
    accessTokenInfo,
  };
};

export const removeAuthInfo = () => {
  return {
    type: ActionTypes.REMOVE_AUTH_INFO,
  };
};

export const setIdTokenInfo = (idTokenInfo) => ({
  type: ActionTypes.SET_ID_TOKEN_INFO,
  idTokenInfo,
});

export const setAuthenticatedInfoAndSaveToLocalStorage = (
  jwtAccessToken,
  jwtIdToken,
  onError
) => {
  return (dispatch) => {
    localStorage.setItem("accessToken", jwtAccessToken);
    localStorage.setItem("idToken", jwtIdToken);
    const action = ActionTypes.SET_ACCESS_TOKEN_INFO;
    const handleError = (e) => {
      dispatch(errorObj({ [action]: e }));
      if (onError) {
        onError(e);
      }
    };
    let decodedJwtAccessToken = null;
    let decodedJwtIdToken = null;

    try {
      decodedJwtAccessToken = jwtDecode(jwtAccessToken || "");
      decodedJwtIdToken = jwtDecode(jwtIdToken || "");
    } catch (e) {
      return handleError(e);
    }

    dispatch(setIdTokenInfo(decodedJwtIdToken));
    return dispatch(setAccessTokenInfo(decodedJwtAccessToken));
  };
};
