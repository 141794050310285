import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./CircleIconButton.module.scss";

const CircleIconButton = ({
  icon,
  className,
  ariaLabel,
  disabled,
  children,
  ...rest
}) => {
  return (
    <button
      aria-label={ariaLabel}
      className={classnames(styles.button, className, {
        [styles.disabled]: disabled,
      })}
      disabled={disabled}
      {...rest}
    >
      {icon}
      {children}
    </button>
  );
};

CircleIconButton.propTypes = {
  icon: PropTypes.any,
  className: PropTypes.string,
};

export default CircleIconButton;
