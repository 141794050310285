import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { PageNavBarLink } from "../";
import styles from "./PageNavBar.module.scss";

/**
 * PageNavBar renders a horizontal series of PageNavBarLinks, useful for navigating to sub-routes within a page, such as in the Organization Profile.
 */

const PageNavBar = ({ options, className }) => {
  return (
    <nav className={classNames(styles.pageNavBar, className)}>
      {options.map((option) => (
        <PageNavBarLink key={option.to} {...option} />
      ))}
    </nav>
  );
};

PageNavBar.propTypes = {
  /**
   * Array of nav options. See PageNavBarLink docs for more details
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      exact: PropTypes.bool,
    })
  ).isRequired,
  /**
   * Optional classname for added styling
   */
  className: PropTypes.string,
};

PageNavBar.defaultProps = {
  className: "",
};

export default PageNavBar;
