import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { VERIFY_ROLE } from "graphql/auth";
import { CollabContext } from "./CollabContext";
import Forbidden403 from "../Errors/Forbidden403";
import { PERMISSIONS_API } from "../../AppApolloProvider";

const ProtectedRoute = ({
  componentToRender: ComponentToRender,
  children,
  propsToPassToComponent,
  roleType,
  ...restProps
}) => {
  const collabContext = useContext(CollabContext);
  const currentCollaborationId = collabContext?.currentCollaborationId;

  const { data: roleData, loading } = useQuery(VERIFY_ROLE, {
    skip: !roleType || !currentCollaborationId,
    variables: {
      roleType,
      resourceUuid: currentCollaborationId,
    },
    context: {
      clientName: PERMISSIONS_API,
    },
  });

  const accessTokenInfo = useSelector(
    (state) => state.authServerReducer.accessTokenInfo
  );

  if (!accessTokenInfo) {
    return (
      <Route
        {...restProps}
        component={() => <Redirect {...restProps} to="/login" />}
      />
    );
  }

  if (roleType && !loading && !roleData?.verifyRole?.success) {
    return <Forbidden403 />;
  }

  const render = (givenProps) => {
    return (
      children || (
        <ComponentToRender {...propsToPassToComponent} {...givenProps} />
      )
    );
  };
  return <Route {...restProps} render={render} />;
};

ProtectedRoute.propTypes = {
  componentToRender: PropTypes.elementType,
  roleType: PropTypes.string,
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
  propsToPassToComponent: PropTypes.any,
};

export default ProtectedRoute;
