import React, { KeyboardEvent } from "react";
import classNames from "classnames";

import styles from "./IconButton.module.scss";

interface CommonProps {
  /** A function that executes on click event. */
  onClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | KeyboardEvent
  ) => void;
  /** Optional class name for additional styling. */
  className?: string;
  /** Boolean to disable the button. */
  disabled?: boolean;
  /** An attribute that assigns an accessible label to the element. */
  ariaLabel: string;
  /** Disable hover effect */
  disableHover?: boolean;
}

interface PropsWithIcon extends CommonProps {
  /** An 'alt' value for the image. NOT REQUIRED if you pass a value to the 'icon' prop. */
  alt: string;
  /** The 'src' of the image. NOT REQUIRED if you pass a value to the 'icon' prop. */
  src: string;
  /** An existing image, e.g., a FontAwesome component. NOT REQUIRED if you pass values to 'src' and 'atl' props. */
  icon?: never;
}

interface PropsWithSrc extends CommonProps {
  /** An 'alt' value for the image. NOT REQUIRED if you pass a value to the 'icon' prop. */
  alt?: never;
  /** The 'src' of the image. NOT REQUIRED if you pass a value to the 'icon' prop. */
  src?: never;
  /** An existing image, e.g., a FontAwesome component. NOT REQUIRED if you pass values to 'src' and 'atl' props. */
  icon: object;
}

type Props = PropsWithIcon | PropsWithSrc;

/**
 * IconButton renders a button with an icon. The icon can be created two ways: (1) by passing
 * an img src, or (2) by passing an existing image, e.g., a FontAwesome component.
 */
const IconButton = ({
  onClick,
  alt,
  src,
  icon,
  className,
  disabled,
  ariaLabel,
  disableHover,
}: Props) => {
  return (
    <button
      className={classNames(styles.button, className, {
        [styles.disableHover]: disableHover,
      })}
      onClick={onClick}
      disabled={disabled}
      aria-label={ariaLabel}
    >
      {icon ? icon : <img src={src} alt={alt} />}
    </button>
  );
};

export default IconButton;
