import React from "react";
import classNames from "classnames";

import { ReactComponent as LoadingIcon } from "../assets/loading-icon.svg";
import styles from "./LoadingIcon.module.scss";

interface Props {
  className?: string;
}

const AnimatedLoadingIcon = ({ className }: Props) => {
  return (
    <>
      <LoadingIcon
        aria-labelledby="loading-icon-title"
        className={classNames(styles.icon, className)}
      />
      <title id="loading-icon-title">Loading Icon</title>
    </>
  );
};

export default AnimatedLoadingIcon;
