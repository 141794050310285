import React from "react";
import IdleTimer, { useIdleTimer } from "react-idle-timer";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AllRoutes from "./components/Navigation/AllRoutes";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";


const App = () => {
  const history = useHistory();
  const onIdle = () => {
    history.push("/login");
  };

  useIdleTimer({
    timeout: 1000 * 1800,
    onIdle,
    debounce: 250
  })

  return (
    <>
      <IdleTimer
        element={document}
        onIdle={onIdle}
        debounce={250}
        timeout={1000 * 1800}
      />
      <ToastContainer
        position="top-center"
        className="toast-container"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <AllRoutes />
    </>
  );
}

export default App;
