import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import styles from "./PageNavBarLink.module.scss";

/**
 * PageNavBarLink provides a navigational link with default styles – useful, for example, in the PageNavBar.
PageNavBarLink handles most of the visual details for you: it uses NavLink (from React router), which handles the highlighting of selected links, and it uppercases labels by default.
 */
const PageNavBarLink = ({ to, label, exact }) => {
  return (
    <NavLink
      className={styles.pageNavBarLink}
      activeClassName={styles.selected}
      exact={exact}
      to={to}
    >
      {label}
    </NavLink>
  );
};

PageNavBarLink.propTypes = {
  /**
   * Page path, passed to React Router Link to prop directly
   */
  to: PropTypes.string.isRequired,
  /**
   * Link Label
   */
  label: PropTypes.string.isRequired,
  /**
   * Whether the path needs to match exactly or not. good for index paths (/)
   */
  exact: PropTypes.bool,
};

PageNavBarLink.defaultProps = {
  exact: false,
};

export default PageNavBarLink;
