import Url from "url-parse";

const _isSafe = (urlToCheck: string): boolean => {
  const url = new Url(urlToCheck, {});
  if (url.protocol === "http:") {
    return true;
  }
  if (url.protocol === "https:") {
    return true;
  }

  return false;
};

export const safeURL = (url: string): string | undefined =>
  _isSafe(url) ? url : undefined;

export const addHttpsIfNotPresent = (urlToCheck: string): string => {
  const url = new Url(urlToCheck, {});

  if (url.protocol === "https:") {
    return urlToCheck;
  }

  url.set("protocol", "https");
  return url.toString();
};
