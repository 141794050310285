import { combineReducers } from "redux";
import authServerReducer from "./AuthServer/reducer";
import errorHandlerReducer from "./ErrorHandler/reducer";
import { AuthServerState } from "./AuthServer/types";

export const rootReducer = combineReducers({
  authServerReducer,
  errorHandlerReducer,
});

export interface RootState {
  authServerReducer: AuthServerState;
  errorHandlerReducer: any;
}
