import { gql } from "@apollo/client";

export const GET_DATA_ASSET_BY_UUID = gql`
  query getDataAsset(
    $ownerUuid: String
    $ownerType: String
    $collaborationUuid: String!
  ) {
    dataAssets(
      ownerUuid: $ownerUuid
      ownerType: $ownerType
      collaborationUuid: $collaborationUuid
    ) {
      totalCount
      dataAssets {
        __typename
        uuid
        archived
        name
        description
        securityLevel
        ... on SharedDataAsset {
          combinedDataAssets {
            uuid
            name
            description
            securityLevel
          }
        }
        ... on CombinedDataAsset {
          destinations {
            uuid
            name
            dateCreated
            dateModified
            description
          }
        }
        ownerType
        owner {
          uuid
          name
        }
        pointOfContact {
          uuid
          givenName
          familyName
          emailAddress
        }
        submitter {
          uuid
          givenName
          familyName
          emailAddress
        }
        modifier {
          uuid
          givenName
          familyName
          emailAddress
        }
      }
    }
  }
`;

export const GET_COLLAB_DATA_ASSET_BY_UUID = gql`
  query getDataAsset($uuid: String!, $dataAssetType: String!) {
    dataAsset(uuid: $uuid, dataAssetType: $dataAssetType) {
      ... on SharedDataAsset {
        combinedDataAssets {
          uuid
          name
          description
          securityLevel
        }
      }
      ... on CombinedDataAsset {
        destinations {
          uuid
          name
          dateCreated
          dateModified
          description
        }
      }
      uuid
      name
      description
      archived
      securityLevel
      dateModified
      ownerType
      owner {
        uuid
        name
      }
      pointOfContact {
        uuid
        givenName
        familyName
        emailAddress
      }
      submitter {
        uuid
        givenName
        familyName
        emailAddress
      }
      modifier {
        uuid
        givenName
        familyName
        emailAddress
      }
      collaborations {
        uuid
        name
      }
    }
  }
`;

export const GET_COMBINED_ASSET_BY_UUID = gql`
  query getCombinedAsset($uuid: String!) {
    combinedDataAsset(uuid: $uuid) {
      uuid
      name
      description
      securityLevel
      ownerType
      owner {
        uuid
        name
      }
      dateModified
      pointOfContact {
        uuid
        givenName
        familyName
        emailAddress
      }
      submitter {
        uuid
        givenName
        familyName
        emailAddress
      }
      modifier {
        uuid
        givenName
        familyName
        emailAddress
      }
      destinations {
        uuid
        name
        dateCreated
        dateModified
        description
      }
    }
  }
`;

export const GET_COLLABORATION = gql`
  query getCollaboration($uuid: String!) {
    collaboration(uuid: $uuid) {
      name
      uuid
      dateCreated
      dateModified
      description
      members {
        uuid
        name
        dateCreated
        dateModified
        description
      }
      thirdParties {
        uuid
        name
        dateCreated
        dateModified
        description
      }
      destinations {
        uuid
        name
        dateCreated
        dateModified
        description
      }
      ownedSharedDataAssets {
        name
      }
      sharedDataAssets {
        uuid
        name
        combinedDataAssets {
          uuid
          name
          destinations {
            uuid
            name
            dateCreated
            dateModified
            description
          }
        }
        description
        securityLevel
        ownerType
        owner {
          uuid
          name
        }
        pointOfContact {
          uuid
          givenName
          familyName
        }
        submitter {
          uuid
          givenName
          familyName
        }
        modifier {
          uuid
          givenName
          familyName
        }
      }
      combinedDataAssets {
        uuid
        name
        destinations {
          uuid
          name
          dateCreated
          dateModified
          description
        }
        description
        securityLevel
        ownerType
        owner {
          uuid
          name
        }
        pointOfContact {
          uuid
          givenName
          familyName
        }
        submitter {
          uuid
          givenName
          familyName
        }
        modifier {
          uuid
          givenName
          familyName
        }
      }
    }
  }
`;

export const CREATE_COLLABORATION = gql`
  mutation createCollaboration($name: String!, $description: String!) {
    createCollaboration(
      attributes: { name: $name, description: $description }
    ) {
      success
    }
  }
`;

export const GET_COLLABORATIONS = gql`
  query getCollaboration {
    collaborations {
      uuid
      name
      dateCreated
      dateModified
      description
      ownedCombinedDataAssets {
        name
      }
      members {
        uuid
        name
        dateCreated
        dateModified
        description
      }
      thirdParties {
        uuid
        name
        dateCreated
        dateModified
        description
      }
      sharedDataAssets {
        uuid
        name
        combinedDataAssets {
          uuid
          name
        }
        description
        securityLevel
        ownerType
        owner {
          uuid
          name
        }
        pointOfContact {
          uuid
          givenName
          familyName
        }
        submitter {
          uuid
          givenName
          familyName
        }
        modifier {
          uuid
          givenName
          familyName
        }
      }
      combinedDataAssets {
        name
        uuid
        destinations {
          uuid
          name
          dateCreated
          dateModified
          description
        }
        description
        securityLevel
        ownerType
        owner {
          uuid
          name
        }
        pointOfContact {
          uuid
          givenName
          familyName
        }
        submitter {
          uuid
          givenName
          familyName
        }
        modifier {
          uuid
          givenName
          familyName
        }
      }
    }
  }
`;

export const GET_ORGANIZATIONS = gql`
  query getOrganizations($collaborationUuid: String!) {
    organizations(collaborationUuid: $collaborationUuid) {
      uuid
      name
      dateCreated
      dateModified
      description
    }
  }
`;

export const GET_ORGANIZATION_BY_UUID = gql`
  query getOrganization($uuid: String!) {
    organization(uuid: $uuid) {
      uuid
      name
      dateCreated
      dateModified
      description
      pointOfContact {
        uuid
        givenName
        familyName
        emailAddress
      }
      persons {
        uuid
        givenName
        familyName
        emailAddress
        dateCreated
        dateModified
      }
    }
  }
`;

export const GET_PERSON = gql`
  query getPerson($uuid: String!) {
    person(uuid: $uuid) {
      uuid
      givenName
      familyName
      emailAddress
      dateCreated
      dateModified
      organization {
        uuid
        name
        dateCreated
        dateModified
        description
      }
    }
  }
`;

export const GET_PERSONS = gql`
  query getPersons {
    persons {
      uuid
      givenName
      familyName
      emailAddress
      dateCreated
      dateModified
    }
  }
`;

export const GET_DATA_ASSETS = gql`
  query getDataAssets(
    $status: [String]
    $ownerType: String
    $dataAssetType: String
    $ownerUuid: String
    $collaborationUuid: String!
  ) {
    dataAssets(
      status: $status
      dataAssetType: $dataAssetType
      ownerUuid: $ownerUuid
      ownerType: $ownerType
      collaborationUuid: $collaborationUuid
    ) {
      dataAssets {
        uuid
        name
        dateModified
        ownerType
        owner {
          uuid
          name
        }
        pointOfContact {
          uuid
          givenName
          familyName
        }
        status
        schema {
          uuid
          name
        }
      }
    }
  }
`;

export const GET_SCHEMA = gql`
  query getSchema(
    $schemaName: String!
    $latestVersion: Boolean
    $versionNumber: Int
  ) {
    getSchema(
      schemaName: $schemaName
      latestVersion: $latestVersion
      versionNumber: $versionNumber
    ) {
      SchemaVersionId
      SchemaDefinition
      DataFormat
      SchemaArn
      VersionNumber
      Status
      CreatedTime
    }
  }
`;

export const GET_DATA_ASSET = gql`
  query getSharedDataAsset($uuid: String!, $dataAssetType: String!) {
    dataAsset(uuid: $uuid, dataAssetType: $dataAssetType) {
      uuid
      name
      ownerType
      status
      owner {
        uuid
        name
      }
      modifier {
        uuid
        givenName
        familyName
        dateModified
      }
      description
      pointOfContact {
        uuid
        givenName
        familyName
      }
      storageService
      useCases
      dataSensitivityClassification
      requestAccess
      accessForApprovedUsers
      collaborations {
        uuid
        name
      }
    }
  }
`;

export const UPDATE_SHARED_DATA_ASSET = gql`
  mutation updateSharedDataAsset($uuid: String!, $status: String!) {
    updateSharedDataAsset(uuid: $uuid, attributes: { status: $status }) {
      sharedDataAsset {
        uuid
        status
      }
      success
    }
  }
`;

export const GET_ORGANIZATION_AND_MEMBERS_BY_UUID = gql`
  query getOrganizationAndMembers($uuid: String!) {
    organization(uuid: $uuid) {
      uuid
      name
      dateCreated
      dateModified
      description
      persons {
        uuid
        givenName
        familyName
        emailAddress
        dateCreated
        dateModified
        organization {
          uuid
          name
        }
      }
    }
  }
`;

export const GET_REQUESTS = gql`
  query getRequests($status: [String]!, $collaborationUuid: String!) {
    requests(status: $status, collaborationUuid: $collaborationUuid) {
      uuid
      name
      dateCreated
      dateModified
      status
      approvedProject
      requestingOrganization {
        uuid
        name
        description
      }
      requestPointOfContact {
        givenName
        familyName
      }
      requestedDataAsset {
        uuid
        name
        description
        owner {
          uuid
          name
          ... on Organization {
            pointOfContact {
              givenName
              familyName
              emailAddress
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_REQUEST = gql`
  mutation updateRequest($uuid: String!, $status: String!) {
    updateRequest(uuid: $uuid, attributes: { status: $status }) {
      success
    }
  }
`;

export const CREATE_ORGANIZATION = gql`
  mutation createOrganization(
    $name: String!
    $description: String!
    $pointOfContactUuid: String!
    $collaborationUuids: [String!]!
  ) {
    createOrganization(
      attributes: {
        name: $name
        description: $description
        pointOfContactUuid: $pointOfContactUuid
        collaborationUuids: $collaborationUuids
      }
    ) {
      success
    }
  }
`;

export const CREATE_SHARED_DATA_ASSET = gql`
  mutation createSharedDataAsset(
    $name: String!
    $ownerUuid: String
    $ownerType: String!
    $securityLevel: String!
    $pointOfContactUuid: String!
    $collaborationUuids: [String!]!
  ) {
    createSharedDataAsset(
      attributes: {
        name: $name
        ownerUuid: $ownerUuid
        ownerType: $ownerType
        securityLevel: $securityLevel
        pointOfContactUuid: $pointOfContactUuid
        collaborationUuids: $collaborationUuids
      }
    ) {
      success
    }
  }
`;

export const UPDATE_ORGANIZATION_FROM_COLLAB_DRAWER = gql`
  mutation updateOrganization(
    $uuid: String!
    $name: String!
    $description: String!
    $pointOfContactUuid: String!
    $collaborationUuids: [String!]
  ) {
    updateOrganization(
      uuid: $uuid
      attributes: {
        name: $name
        description: $description
        pointOfContactUuid: $pointOfContactUuid
        collaborationUuids: $collaborationUuids
      }
    ) {
      success
    }
  }
`;

export const UPDATE_SHARED_DATA_ASSET_FROM_COLLAB_DRAWER = gql`
  mutation updateSharedDataAsset(
    $uuid: String!
    $name: String!
    $ownerUuid: String
    $ownerType: String!
    $securityLevel: String!
    $pointOfContactUuid: String!
    $collaborationUuids: [String!]!
  ) {
    updateSharedDataAsset(
      uuid: $uuid
      attributes: {
        name: $name
        ownerUuid: $ownerUuid
        ownerType: $ownerType
        securityLevel: $securityLevel
        pointOfContactUuid: $pointOfContactUuid
        collaborationUuids: $collaborationUuids
      }
    ) {
      success
    }
  }
`;

export const UPDATE_COMBINED_DATA_ASSET_FROM_COLLAB_DRAWER = gql`
  mutation updateCombinedDataAsset(
    $uuid: String!
    $name: String!
    $ownerUuid: String
    $ownerType: String!
    $securityLevel: String!
    $pointOfContactUuid: String!
    $collaborationUuids: [String!]!
  ) {
    updateCombinedDataAsset(
      uuid: $uuid
      attributes: {
        name: $name
        ownerUuid: $ownerUuid
        ownerType: $ownerType
        securityLevel: $securityLevel
        pointOfContactUuid: $pointOfContactUuid
        collaborationUuids: $collaborationUuids
      }
    ) {
      success
    }
  }
`;

export const UPDATE_DESTINATION_FROM_COLLAB_DRAWER = gql`
  mutation updateDestination(
    $uuid: String!
    $name: String!
    $description: String!
    $collaborationUuids: [String!]!
  ) {
    updateDestination(
      uuid: $uuid
      attributes: {
        name: $name
        description: $description
        collaborationUuids: $collaborationUuids
      }
    ) {
      success
    }
  }
`;

export const UPDATE_SHARED_DATA_ASSET_EDGES = gql`
  mutation updateSharedDataAsset(
    $uuid: String!
    $combinedDataAssetUuids: [String!]
    $ownerUuid: String
  ) {
    updateSharedDataAsset(
      uuid: $uuid
      attributes: {
        combinedDataAssetUuids: $combinedDataAssetUuids
        ownerUuid: $ownerUuid
      }
    ) {
      success
    }
  }
`;

export const UPDATE_COMBINED_DATA_ASSET_EDGES = gql`
  mutation updateCombinedDataAsset(
    $uuid: String!
    $destinationUuids: [String!]
    $sharedDataAssetUuids: [String!]
  ) {
    updateCombinedDataAsset(
      uuid: $uuid
      attributes: {
        destinationUuids: $destinationUuids
        sharedDataAssetUuids: $sharedDataAssetUuids
      }
    ) {
      success
    }
  }
`;

export const UPDATE_DESTINATION_EDGES = gql`
  mutation updateDestination(
    $uuid: String!
    $combinedDataAssetUuids: [String!]!
  ) {
    updateDestination(
      uuid: $uuid
      attributes: { combinedDataAssetUuids: $combinedDataAssetUuids }
    ) {
      success
    }
  }
`;

export const CREATE_COMBINED_DATA_ASSET = gql`
  mutation createCombinedDataAsset(
    $name: String!
    $ownerUuid: String
    $ownerType: String!
    $securityLevel: String!
    $pointOfContactUuid: String!
    $collaborationUuids: [String!]!
  ) {
    createCombinedDataAsset(
      attributes: {
        name: $name
        ownerUuid: $ownerUuid
        ownerType: $ownerType
        securityLevel: $securityLevel
        pointOfContactUuid: $pointOfContactUuid
        collaborationUuids: $collaborationUuids
      }
    ) {
      success
    }
  }
`;

export const CREATE_DESTINATION = gql`
  mutation createDestination(
    $name: String!
    $description: String!
    $collaborationUuids: [String!]!
  ) {
    createDestination(
      attributes: {
        name: $name
        description: $description
        collaborationUuids: $collaborationUuids
      }
    ) {
      success
    }
  }
`;

export const GET_COLLABORATIONS_FOR_DROPDOWN = gql`
  query getCollaboration {
    collaborations {
      uuid
      name
      dateCreated
      dateModified
      description
    }
  }
`;
