import React from "react";

const Message = (props) => {
  return (
    <>
      <h1 className="mb-5">404 - Page Not Found</h1>
      <p className="xl-font">
        We're sorry you landed here! The page you are looking for cannot be
        found.
      </p>
      <p className="xl-font">{props.children}</p>
    </>
  );
};

export default Message;
