import React from "react";

import styles from "./Subsection.module.scss";

interface BaseProps {
  header: string;
}

interface ContentProps extends BaseProps {
  content: string;
}

interface ChildrenProps extends BaseProps {
  children: React.ReactElement;
}

const isContentProps = (
  props: ContentProps | ChildrenProps
): props is ContentProps => {
  return typeof (props as ContentProps).content === "string";
};

function Subsection({ header, content }: ContentProps): React.ReactElement;
function Subsection({ header, children }: ChildrenProps): React.ReactElement;

function Subsection(props: ContentProps | ChildrenProps) {
  let content = null;
  if (isContentProps(props)) {
    content = <p>{props.content}</p>;
  } else {
    content = props.children;
  }

  return (
    <section className={styles.section}>
      <h4 className={styles.header}>{props.header}</h4>
      {content}
    </section>
  );
}

export default Subsection;
