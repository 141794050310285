import React, { useContext } from "react";
import { withRouter } from "react-router";
import {
  TopNavOption,
  IconButton,
  ProfileAvatarIcon,
  Badge,
  LoadingIcon,
} from "presentational-components";
import { Link, useLocation } from "react-router-dom";
import classnames from "classnames";
import expandIcon from "assets/expand-icon.svg";
import styles from "./DataResourceNav.module.scss";
import { SideNavContext } from "../../Navigation/SideNavContext";
import { useSelector } from "react-redux";
import ShareButton from "../ShareButton";
import StopSharingButton from "../StopSharingButton";
import { SHARED, SHARED_DATA_ASSET, STAGED } from "../constants";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import {
  UPDATE_SHARED_DATA_ASSET,
  GET_DATA_ASSETS,
  GET_PERSON,
  GET_DATA_ASSET,
} from "graphql/data";
import NavDropdown from "../../Navigation/NavDropdown/NavDropdown";
import { CollabContext } from "../../Navigation/CollabContext";
import Upload from "../../DataUploader";

const DataResourceNav = ({
  ownerType,
  name,
  dataButtons,
  backLink,
  dataUrl,
  dataDictionaryUrl,
  dataAboutUrl,
  dataAccessUrl,
  status,
  uuid,
  loading,
  addDataButton,
}) => {
  const collabContext = useContext(CollabContext);
  const collaborations = collabContext?.collaborations || [];
  const currentCollaborationId = collabContext?.currentCollaborationId;
  const setCurrentCollaborationId = collabContext?.setCurrentCollaborationId;

  const formattedCollaborations = collaborations.map((curr) => ({
    ...curr,
    label: curr?.name,
    value: curr?.uuid,
  }));

  const { sideNavExpanded, setSideNavExpanded } = useContext(SideNavContext);
  const idTokenInfo = useSelector(
    (state) => state.authServerReducer.idTokenInfo
  );
  const userUUID = idTokenInfo?.person_uuid;

  const { data: personData } = useQuery(GET_PERSON, {
    variables: { uuid: userUUID },
    skip: !userUUID,
  });

  const { pathname } = useLocation();
  const [updateSharedDataAsset] = useMutation(UPDATE_SHARED_DATA_ASSET, {
    refetchQueries: [
      {
        query: GET_DATA_ASSET,
        variables: {
          uuid,
          dataAssetType: SHARED_DATA_ASSET,
          collaborationUuid: currentCollaborationId,
        },
      },
      {
        query: GET_DATA_ASSETS,
        variables: {
          status: [STAGED, SHARED],
          dataAssetType: SHARED_DATA_ASSET,
          collaborationUuid: currentCollaborationId,
        },
      },
    ],
  });

  const onClickShare = () => {
    updateSharedDataAsset({
      variables: { uuid, status: SHARED },
    })
      .then(() => toast.success("Successfully shared data."))
      .catch(() => {});
  };

  const onClickStopSharing = () => {
    updateSharedDataAsset({
      variables: { uuid, status: STAGED },
    })
      .then(() => toast.success("Successfully unshared data."))
      .catch(() => {});
  };

  const profileOptions = [
    {
      label: "My Profile",
      to: "/profile",
    },
    {
      label: "Log out",
      to: "/logout",
    },
  ];

  const profileButton = (openMenu) => {
    return (
      <button
        className={styles.roundedButton}
        onClick={openMenu}
        aria-label="profile"
      >
        <ProfileAvatarIcon
          firstname={personData?.person?.givenName}
          lastname={personData?.person?.familyName}
          email={personData?.person?.emailAddress}
          id={personData?.person?.uuid}
        />
      </button>
    );
  };

  return (
    <nav className={styles.navContainer} aria-label="primary-nav">
      <div className="d-flex w-100">
        {!sideNavExpanded && (
          <IconButton
            ariaLabel="expand side navigation"
            className={styles.hamburger}
            onClick={() => setSideNavExpanded(true)}
            src={expandIcon}
            alt="expand-side-nav"
          />
        )}
        <div className="flex-grow-1 d-flex flex-column">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <div className="flex-grow-1 d-flex align-items-center">
              {loading ? (
                <LoadingIcon />
              ) : (
                <>
                  <h2 className="mb-0 mr-5">{name}</h2>
                  <Badge className="mb-0 mr-4" value={ownerType} />
                  {dataButtons && (
                    <>
                      <StopSharingButton
                        className="mr-2"
                        disabled={status === STAGED}
                        onClick={onClickStopSharing}
                      />
                      <ShareButton
                        className="mr-4"
                        disabled={status === SHARED}
                        onClick={onClickShare}
                      />
                      {addDataButton && <Upload />}
                    </>
                  )}
                </>
              )}
            </div>
            <div className="d-flex align-items-center">
              <NavDropdown
                defaultLabel="Collaborations"
                onSelectOption={(option) =>
                  setCurrentCollaborationId(option.value)
                }
                options={formattedCollaborations}
                selectedValue={currentCollaborationId}
              />
              <TopNavOption
                className={styles.navOptionRightSection}
                renderButton={profileButton}
                options={profileOptions}
                rightAlignMenu
                withBorders
                width={145}
              />
            </div>
          </div>
          {backLink}
          <div>
            {dataUrl && (
              <Link
                className={classnames(styles.tab, {
                  [styles.activeTab]: pathname === dataUrl,
                })}
                to={dataUrl}
              >
                Data
              </Link>
            )}
            {dataDictionaryUrl && (
              <Link
                className={classnames(styles.tab, {
                  [styles.activeTab]: pathname === dataDictionaryUrl,
                })}
                to={dataDictionaryUrl}
              >
                Data Dictionary
              </Link>
            )}
            {dataAboutUrl && (
              <Link
                className={classnames(styles.tab, {
                  [styles.activeTab]: pathname === dataAboutUrl,
                })}
                to={dataAboutUrl}
              >
                About
              </Link>
            )}
            {dataAccessUrl && (
              <Link
                className={classnames(styles.tab, {
                  [styles.activeTab]: pathname === dataAccessUrl,
                })}
                to={dataAccessUrl}
              >
                Access
              </Link>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default withRouter(DataResourceNav);
