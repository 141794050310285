import { gql } from "@apollo/client";

export const COLLABORATION_ADMIN = "COLLABORATION_ADMIN";

export const VERIFY_ROLE = gql`
  query verifyRole($roleType: RoleType!, $resourceUuid: String!) {
    verifyRole(roleType: $roleType, resourceUuid: $resourceUuid) {
      success
      timestamp
    }
  }
`;
