import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Forbidden403 = () => {
  return (
    <Row className="mt-xl ml-5 text-blue">
      <Col xs={{ span: 11 }}>
        <h1 className="mb-5">403 - Forbidden</h1>
        <p className="xl-font">
          You do not have permission to view this page. Please contact
          Brighthive or your technical administrator to learn about your user
          scope.
        </p>
        <p className="xl-font">
          <Link to="/profile">
            <FontAwesomeIcon className="mr-2" icon={faArrowAltCircleRight} />
            Go to your profile page.
          </Link>
        </p>
      </Col>
    </Row>
  );
};

export default Forbidden403;
