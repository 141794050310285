import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";
import SideNavBar from "../SideNavBar";
import styles from "./NavContainer.module.scss";
import { SideNavContext } from "../SideNavContext";
import classnames from "classnames";

interface Props {
  children: JSX.Element | JSX.Element[];
  renderNavComponent: Function;
}

const NavContainer = ({ children, nav }: Props) => {
  const accessTokenInfo = useSelector(
    (state) => state.authServerReducer.accessTokenInfo
  );
  const {
    sideNavExpanded,
    setSideNavExpanded,
    dataOptionsExpanded,
    setDataOptionsExpanded,
  } = useContext(SideNavContext);

  // shouldn't render on login page
  if (!accessTokenInfo) {
    return children;
  }

  return (
    <div>
      <div className="d-flex">
        <SideNavBar
          sideNavExpanded={sideNavExpanded}
          setSideNavExpanded={setSideNavExpanded}
          dataOptionsExpanded={dataOptionsExpanded}
          setDataOptionsExpanded={setDataOptionsExpanded}
        />
        <div className={styles.navAndContent}>
          {nav}
          <main
            className={classnames(styles.main, {
              [styles.mainExpanded]: !sideNavExpanded,
            })}
          >
            {children}
          </main>
        </div>
      </div>
    </div>
  );
};

export default withRouter(NavContainer);
