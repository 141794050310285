export const filterByMultiple = (filterVal, data, dataField) => {
  if (
    !Array.isArray(filterVal) ||
    (Array.isArray(filterVal) && filterVal.length === 0)
  ) {
    return data;
  }
  return data.filter((curr) => {
    return filterVal.some((currFilterVal) => currFilterVal === curr[dataField]);
  });
};
