import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";

import { DEFAULT_ERROR_MESSAGE } from "constants/ErrorMessages";

export const headerWithJwt = () => {
  const jwt = localStorage.getItem("accessToken");

  const headers = {
    "content-type": "application/json",
    authorization: `Bearer ${jwt}`,
  };

  return headers;
};

export const getAccessToken = () => {
  let accessToken = "";
  try {
    const jwt: string = localStorage.getItem("accessToken") || "";
    const decodedJwt: { "brighthive-access-token": string } = jwtDecode(jwt);
    accessToken = decodedJwt["brighthive-access-token"];
  } catch (e) {
    console.error(e);
  }

  return accessToken;
};

export const getJwtToken = () => {
  return localStorage.getItem("accessToken") || "";
};

export const headerWithToken = () => {
  const headers = {
    "content-type": "application/json",
    authorization: `Bearer ${getAccessToken()}`,
  };

  return { headers };
};

export const headerWithJWT = () => {
  const headers = {
    "content-type": "application/json",
    authorization: `Bearer ${getJwtToken()}`,
  };

  return { headers };
};

/**
 * Prep request body for backend
 * converts empty string values to null to sidestep backend validation for empty values
 */
export const prepRequestBody = (body: object) => {
  const bodyCopy = JSON.parse(JSON.stringify(body));

  for (const key in bodyCopy) {
    if (bodyCopy[key] === "") {
      bodyCopy[key] = null;
    }
  }

  return bodyCopy;
};

export const handleErrorWithToast = (e, message = DEFAULT_ERROR_MESSAGE) => {
  console.error(e);
  toast.error(message);
};

export const handleSuccessWithToast = (message = "Request successfull") => {
  toast.success(message);
};
