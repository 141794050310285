import React from "react";
import { Link } from "react-router-dom";
import styles from "./CollabOverview.module.scss";
import { LinkOrButton } from "../../presentational-components";
import classnames from "classnames";

const DATA_UPLOADER_USER_GUIDE =
  "https://docs.google.com/presentation/d/1NqDNdNirOY0RDR18glzb-tLYboEuIoF2Zzo3r0gkKfM/edit#slide=id.g10a9415a8bb_0_154";
const DATA_GUIDE =
  "https://docs.google.com/document/d/1SKwn_IxQBxYj3UjxxG2u48aOvPg8aJ_q/edit";
const BRIGHTHIVE_SECURITY_DOCUMENTATION =
  "https://drive.google.com/file/d/1Kt4ZvhWJUysdfCi0FuA14op-eIlMItES/view";

const Overview = () => {
  return (
    <div className="d-flex">
      <div className="d-flex flex-column mr-5">
        <LinkOrButton href={DATA_UPLOADER_USER_GUIDE}>
          Data Uploader User Guide
        </LinkOrButton>
        <LinkOrButton href={DATA_GUIDE}>Data Guide</LinkOrButton>
        <LinkOrButton href={BRIGHTHIVE_SECURITY_DOCUMENTATION}>
          Brighthive Security Documentation
        </LinkOrButton>
      </div>
      <Link
        className={classnames("btn btn-primary", styles.linkButton)}
        to="/data/my-data"
      >
        Add Data
      </Link>
    </div>
  );
};

export default Overview;
