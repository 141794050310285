import React, { memo, useMemo } from "react";
import classNames from "classnames";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import { useFixedLayoutTable } from "utils/hooks";

import styles from "./HomeTable.module.scss";

interface Props {
  /**
   * This prop contains the column meta data, including the header name, column widths, and the associated data key.
   * It is memoized for you so you don't have to memoize it.
   */
  columns: {
    Header: string;
    accessor: string;
    width?: number;
    Cell?: () => JSX.Element | JSX.Element[];
  }[];
  /**
   * This prop contains the table data (an array of objects) where the key should match the accessor of the column
   * It is memoized for you so you don't have to memoize it.
   */
  data: {}[];
  /**
   * optional className
   */
  className?: string;
  /**
   * control showing loading message
   */
  loading?: boolean;
  /**
   * message to show when loading
   */
  loadingMessage?: string;
  /**
   * message to show when table data is empty
   */
  emptyTableMessage?: string | JSX.Element;
  filter?: boolean;
}

const defaultProps = {
  loading: false,
  emptyTableMessage: "There is no data",
};

/**
 * Table component for the Home Page. This component wraps react-table (https://react-table.tanstack.com/docs/quick-start) with styling.
 * react-table is a headless table library - meaning all the functionality with none of the styles to wrestle with.
 * This table can be extended with the comprehensive functionalities of react-table.
 */

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`Search here.`}
        className={styles.filterInput}
      />
    </span>
  );
}

const HomeTable = ({
  columns: unprocessedColumns,
  data,
  className,
  loading,
  loadingMessage,
  emptyTableMessage,
  filter,
}: Props) => {
  const { isFixedLayoutTable, columns } = useFixedLayoutTable(
    unprocessedColumns
  );
  const memoizedData = useMemo(() => data, [data]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable({ columns, data: memoizedData }, useFilters, useGlobalFilter);

  return (
    <>
      <section className={styles.filterSection}>
        {filter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        )}
      </section>

      <div className={classNames(className, styles.tableContainer)}>
        <table
          className={classNames(styles.table)}
          style={{
            tableLayout: isFixedLayoutTable ? "fixed" : "auto",
          }}
          {...getTableProps()}
        >
          <thead className={styles.thead}>
            {headerGroups.map((headerGroup) => (
              <tr className={styles.tr} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    className={styles.headerCell}
                    style={{ width: column.width }}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody className={styles.tbody} {...getTableBodyProps()}>
            {loading ? (
              <tr>
                <td className={styles.loading}>{loadingMessage}</td>
              </tr>
            ) : !rows.length ? (
              <tr>
                <td
                  className={styles.emptyTableMessage}
                  colSpan={columns.length}
                >
                  {emptyTableMessage}
                </td>
              </tr>
            ) : (
              rows.map((row, rowIndex) => {
                prepareRow(row);
                return (
                  <tr className={classNames(styles.tr)} {...row.getRowProps()}>
                    {row.cells.map((cell, cellIndex) => {
                      return (
                        <td
                          className={classNames(styles.cell, {
                            [styles.cellFirstRow]: rowIndex === 0,
                            [styles.cellLastRow]: rowIndex === rows.length - 1,
                          })}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      <div className={styles.table_addInfo}>
        Showing the first 20 results of {rows.length} rows
      </div>
    </>
  );
};

HomeTable.defaultProps = defaultProps;

// when using memo to make proptype docs appear memo must be wrapped on the export instead of on the function definition
export default memo(HomeTable);
