import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import classNames from "classnames";
import { safeURL } from "utils/Security/validUrl";

import styles from "./LinkOrButton.module.scss";

interface Props {
  /**
   * will render an anchor tag
   */
  href?: string;
  /**
   * will render a React Router Link. passing neither will render a button
   */
  to?: string;
  children?: string | object;
  className?: string;
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
  ) => void;
  disabled?: boolean;
  ariaLabel?: string;
}

/**
 * This component can render an anchor tag, react router Link, or button with the stylings of a basic Brighthive link
 * Rest props are passed through to the element
 */

const LinkOrButton = ({
  href,
  to,
  children,
  className,
  disabled,
  ariaLabel,
  ...props
}: Props) => {
  const commonProps = {
    className: classNames(styles.link, className, disabled && styles.disabled),
    disabled,
    "aria-label": ariaLabel,
  };

  if (href) {
    return (
      <a
        className={classNames(styles.link, className)}
        target="_blank"
        rel="noreferrer"
        href={safeURL(href)}
        {...props}
      >
        {children}
      </a>
    );
  }

  if (to) {
    return (
      <ReactRouterLink {...commonProps} to={to} {...props}>
        {children}
      </ReactRouterLink>
    );
  }

  return (
    <button {...commonProps} {...props}>
      {children}
    </button>
  );
};

export default LinkOrButton;
