import React from "react";

const CSV_FILE_UPLOAD_ERROR_MESSAGES: {
  [key: string]: JSX.Element;
} = Object.freeze({
  GENERAL: (
    <span>
      There was an error uploading your file. Please contact Brighthive for
      assistance.
    </span>
  ),
  SIZE: (
    <span>
      The selected file is too large. Please contact Brighthive for assistance.
    </span>
  ),
  FILE_TYPE: (
    <span>
      The selected file is not an accepted file type. Please upload a .csv file.
    </span>
  ),
  MULTIPLE: <span>Please upload one file at a time.</span>,
});

export const DEFAULT_ERROR_MESSAGE = `Oops, we have a problem. Unfortunately, your request didn't finish. Please try again. If you continue to receive this error message, please
contact the Brighthive team.`;

const constants = {
  CSV_FILE_UPLOAD_ERROR_MESSAGES,
  DEFAULT_ERROR_MESSAGE,
};

export default constants;
