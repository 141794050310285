import React from "react";
import { Dropdown } from "react-bootstrap";
import { ReactComponent as NavExpandBottomIcon } from "assets/nav-expand-bottom-icon.svg";
import styles from "./NavDropdown.module.scss";
import classnames from "classnames";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    className={styles.customToggle}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </button>
));

const NavDropdown = ({
  options,
  onSelectOption,
  selectedValue,
  defaultLabel,
  className
}) => {
  const selectedOption = options.find(
    (option) => option.value === selectedValue
  );

  return (
    <Dropdown className={classnames(styles.dropdown, className)}>
      <Dropdown.Toggle as={CustomToggle}>
        {selectedOption ? selectedOption.label : defaultLabel}
        <NavExpandBottomIcon className={styles.navExpandBottomIcon} />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {options.map((option) => (
          <Dropdown.Item
            className={styles.dropdownItem}
            active={selectedValue === option.value}
            onClick={() => onSelectOption(option)}
            eventKey={option.value}
          >
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavDropdown;
