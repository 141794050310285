import React, { Component } from "react";
import classNames from "classnames";

import getEnv from "utils/Envs";
import styles from "./BasePage.module.scss";

class BasePage extends Component {
  render() {
    const { className, children } = this.props;

    return (
      <div className={classNames(styles.basePage, className)}>
        <div className={styles.content}>{children}</div>
        <div className={styles.footer}>
          <a
            className="outline"
            href={getEnv("REACT_APP_PRIVACY_POLICY_LINK")}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    );
  }
}

export default BasePage;
