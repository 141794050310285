import React, { useRef, useState } from "react";
import classNames from "classnames";

import { useClickOutside } from "utils/hooks/EventHooks";
import { ReactComponent as ChevronDownIcon } from "../assets/chevron-down-icon.svg";
import styles from "./Dropdown.module.scss";
interface Props {
  /**
   * The button label. Could be a react element
   */
  label?: string | JSX.Element;
  /**
   * The menu options in the dropdown.
   */
  options: {
    label: string;
    value?: any;
    onClick?: Function;
  }[];
  containerClassName?: string;
  buttonClassName?: string;
  menuClassName?: string;

  /**
   * To hide dropdown arrow icon
   */
  hideDropdownArrow?: boolean;

  /**
   * Align dropdown menu to right of button
   */
  rightAlignMenu?: boolean;
  inline?: boolean;
  ariaLabel?: string;
  /**
   * to use as a Select instead
   */
  onChange?: Function;
  disabled?: boolean;
  /**
   * Make button span width of parent
   */
  stretch?: boolean;
}

/**
 * Dropdown component
 */
const Dropdown = ({
  label,
  options,
  containerClassName,
  buttonClassName,
  menuClassName,
  hideDropdownArrow,
  rightAlignMenu,
  ariaLabel,
  onChange,
  disabled,
  inline,
  stretch,
}: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const closeMenu = () => {
    setIsMenuOpen(false);
  };
  const openMenu = (): void => {
    setIsMenuOpen(true);
  };

  useClickOutside(closeMenu, menuRef.current);

  return (
    <div
      className={classNames(
        styles.container,
        containerClassName,
        inline && styles.containerInline
      )}
    >
      <button
        onClick={openMenu}
        className={classNames(
          styles.dropdownButton,
          buttonClassName,
          stretch && styles.dropdownButtonStretch
        )}
        aria-label={ariaLabel}
        disabled={disabled}
      >
        <span>{label}</span>
        {hideDropdownArrow || disabled || (
          <ChevronDownIcon
            className={classNames(styles.dropdownIndicator, {
              [styles.dropdownIndicatorOpen]: isMenuOpen,
            })}
            aria-hidden="true"
          />
        )}
      </button>
      <div
        ref={menuRef}
        className={classNames(
          styles.dropdownMenu,
          isMenuOpen && styles.dropdownMenuOpen,
          rightAlignMenu && styles.dropdownMenuRightAlign,
          menuClassName
        )}
        role="menu"
        hidden={!isMenuOpen}
      >
        {options.map((option, index) => {
          const { label, onClick } = option;
          const handleClick = (e) => {
            if (onClick) {
              onClick(e);
            }

            if (onChange) {
              onChange(option);
            }

            closeMenu();
          };
          const handleKeyDown = (e) => {
            if (e.key === "Enter") {
              handleClick(e);
            }
          };

          return (
            <div
              key={label}
              tabIndex={0}
              className={styles.menuOption}
              onClick={handleClick}
              role="menuitem"
              onKeyDown={handleKeyDown}
            >
              {label}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Dropdown;
