import React from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Button, Modal } from "react-bootstrap";
import { ReactComponent as CollaborateIcon } from "assets/collaborate-icon.svg";
import { CircleIconButton } from "presentational-components";
import { useModal } from "utils/hooks/ModalHooks";
import styles from "../common.module.scss";

const ShareButton = ({ disabled, className, onClick }) => {
  const [modalOpen, openModal, closeModal] = useModal();
  const onClickShare = () => {
    onClick();
    closeModal();
  };

  return (
    <>
      <CircleIconButton
        icon={<CollaborateIcon />}
        onClick={openModal}
        disabled={disabled}
        className={className}
      />
      <Modal
        contentClassName={styles.modal}
        show={modalOpen}
        onHide={closeModal}
      >
        <Modal.Header>Share Data?</Modal.Header>
        <Modal.Body>
          You’re about to add this data to the list of shared data resources.
        </Modal.Body>
        <div className="d-flex justify-content-end">
          <Button
            className={styles.cancelButton}
            onClick={closeModal}
            size="lg"
            variant="outline-primary"
          >
            Cancel
          </Button>
          <Button onClick={onClickShare} size="lg" variant="primary">
            Share
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ShareButton;
