import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  multiSelectFilter,
} from "react-bootstrap-table2-filter";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import CustomSearch from "./CustomSearch";
import { Card } from "react-bootstrap";
import { filterByMultiple } from "./helpers";
import { MultiSelectFilter } from "./index";
import classnames from "classnames";
import styles from "./CustomBootstrapTable.module.scss";

//! Date columns that need sorting must use unix values for the sort to work. This is because we're using an old version
//! of react-bootstrap-table-next due to high severity security vulnerabilities in more recent versions and because
//! the library is no longer maintained, making it impossible to fix the vulnerability.
const CustomBootstrapTable = ({
  keyField,
  data,
  columns,
  noDataIndication,
  tableHeader,
  searchEnabled,
  headerElements,
  headerItemsClassName,
  cardClassName,
  tableHeaderClassName,
  tableClassName,
  ...rest
}) => {
  const getAllUniqueValuesOnObjects = (objs, key) => {
    const uniqueValues = [...new Set(objs.map((curr) => curr[key]))];
    return uniqueValues;
  };

  const headerFormatter = (column, _, elements) => {
    const { filterElement, sortElement } = elements;
    return (
      <div className="d-flex align-items-center">
        <div className={styles.headerColumnText}>{column.text}</div>
        {filterElement}
        {sortElement}
      </div>
    );
  };

  const formatEachColumnForMultiSelect = (columns, data) => {
    return columns.map((column) => {
      if (column.multiSelectFilter) {
        const { dataField } = column;
        const nonEmptyValues = data.filter(
          (curr) =>
            curr[dataField] !== "" &&
            curr[dataField] !== null &&
            curr[dataField] !== undefined
        );
        const options = getAllUniqueValuesOnObjects(nonEmptyValues, dataField);
        return {
          headerFormatter: headerFormatter,
          ...column,
          filter: multiSelectFilter({
            options,
            onFilter: (filterVal, data) =>
              filterByMultiple(filterVal, data, dataField),
          }),
          filterRenderer: (onFilter, column) => (
            <MultiSelectFilter
              options={options}
              onFilter={onFilter}
              column={column}
            />
          ),
        };
      } else {
        return column;
      }
    });
  };

  const formattedColumns = formatEachColumnForMultiSelect(columns, data);

  return (
    <Card className={classnames(styles.tableCard, cardClassName)}>
      <ToolkitProvider
        bootstrap4
        data={data}
        keyField={keyField}
        columns={formattedColumns}
        search={{ searchFormatted: searchEnabled }}
      >
        {(toolkitprops) => (
          <>
            {(searchEnabled || tableHeader || headerElements) && (
              <div
                className={classnames(styles.tableHeader, tableHeaderClassName)}
              >
                {tableHeader && (
                  <div
                    aria-label="table-header-text"
                    className={styles.tableHeaderText}
                  >
                    {tableHeader}
                  </div>
                )}
                <div
                  className={classnames(
                    "d-flex align-items-center",
                    headerItemsClassName
                  )}
                >
                  {searchEnabled && (
                    <CustomSearch
                      onSearch={toolkitprops.searchProps.onSearch}
                    />
                  )}
                  {headerElements}
                </div>
              </div>
            )}
            <BootstrapTable
              bootstrap4
              bordered={false}
              data={data}
              columns={columns}
              noDataIndication={noDataIndication}
              filter={filterFactory()}
              wrapperClasses={classnames(styles.table, tableClassName)}
              {...toolkitprops.baseProps}
              {...rest}
            />
          </>
        )}
      </ToolkitProvider>
    </Card>
  );
};

export default CustomBootstrapTable;
