import React from "react";

import styles from "./PlaceholderText.module.scss";

interface Props {
  children: string | (string | JSX.Element)[];
}

/**
 * Text component for the greyed out placeholder
 */
const PlaceholderText = ({ children }: Props) => {
  return <span className={styles.placeholder}>{children}</span>;
};

export default PlaceholderText;
