import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router";

import { toast } from "react-toastify";
import constants from "./constants/ErrorMessages";
import { setAuthenticatedInfoAndSaveToLocalStorage } from "./store/AuthServer/actionCreators";
import { checkIfTokenExpired } from "./utils/Auth";

const HydrationStation = (props) => {
  // DO NOT REDIRECT TO THESE ROUTES
  const authRoute = useRouteMatch("/auth/redirect");
  const loginRoute = useRouteMatch("/login");

  const dispatch = useDispatch();
  const [hydrated, setHydrated] = useState(false);

  useEffect(() => {
    const handleError = () => {
      toast.error(constants.DEFAULT_ERROR_MESSAGE);
      localStorage.removeItem("accessToken");
      localStorage.removeItem("idToken");
      localStorage.removeItem("refreshToken");
    };

    const accessToken = localStorage.getItem("accessToken");
    const idToken = localStorage.getItem("idToken");
    const refreshToken = localStorage.getItem("refreshToken");
    //TODO check if tokens expired
    const accessTokenExpired = checkIfTokenExpired(accessToken);
    const idTokenExpired = checkIfTokenExpired(idToken);

    let tokensExpired = accessTokenExpired || idTokenExpired;

    if (accessToken && idToken && refreshToken && !tokensExpired) {
      dispatch(
        setAuthenticatedInfoAndSaveToLocalStorage(
          accessToken,
          idToken,
          handleError
        )
      );
    } else {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("idToken");
      localStorage.removeItem("refreshToken");
    }
    if (
      !authRoute &&
      !loginRoute &&
      (tokensExpired || !accessToken || !idToken || !refreshToken)
    ) {
      sessionStorage.setItem("redirectUrl", window.location.pathname);
    }
    setHydrated(true);
  }, []); // eslint-disable-line

  return hydrated ? props.children : null;
};

export default HydrationStation;
