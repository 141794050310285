export const STAGED = "staged";
export const SHARED = "shared";
export const COMBINED = "combined";
export const REQUESTED = "requested";
export const PROCESSING = "processing";
export const READY = "ready";
export const AWAITING_DATA = "awaiting data";
export const PENDING = "pending";

export const SHARED_DATA_ASSET = "sharedDataAsset";
export const COMBINED_DATA_ASSET = "combinedDataAsset";

export const SHARED_DATA = "sharedData";
export const COMBINED_DATA = "combinedData";
export const ORGANIZATION = "organization";
export const DESTINATION = "destination";
