import React from "react";
import Nav from "react-bootstrap/Nav";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import styles from "./NavItem.module.scss";

interface Props {
  path: string;
  label: string;
  Icon?: React.SFC<React.SVGProps<SVGSVGElement>>;
  className?: string;
  exact?: boolean;
}

const NavItem = ({ path, label, Icon, className, exact }: Props) => {
  const iconTitleId = `${label}IconTitle`;
  return (
    <Nav.Item>
      <NavLink
        to={path}
        className={classnames("nav-item__navLink", className)}
        activeClassName={styles.selected}
        aria-label={label}
        exact={exact}
      >
        <title id={iconTitleId}>{`${label} Icon`}</title>
        {Icon && (
          <Icon
            className={classnames("nav-item__navIcon")}
            role="img"
            aria-labelledby={iconTitleId}
          />
        )}
        <span className={classnames("nav-item__label")}>{label}</span>
      </NavLink>
    </Nav.Item>
  );
};

export default NavItem;
