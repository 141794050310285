import React from "react";
import classNames from "classnames";

import { LoadingIcon } from "../";
import styles from "./LoadingOverlay.module.scss";

interface Props {
  className?: string;
}

/**
 * Loading Overlay component. When you need to hide something while it loads. It fills the dimensions of the parent element.
 */

const LoadingOverlay = ({ className }: Props) => {
  return (
    <div className={classNames(styles.loadingOverlay, className)}>
      <LoadingIcon />
    </div>
  );
};

export default LoadingOverlay;
