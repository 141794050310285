import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import brighthiveFacetLogo from "assets/brighthive-logo-large.svg";
import { removeAuthInfo } from "store/AuthServer/actionCreators";
import getEnv from "utils/Envs";
import { Image } from "react-bootstrap";

import styles from "./Login.module.scss";
import { BasePage } from "presentational-components";

const Login = () => {
  const dispatch = useDispatch();

  const authURI = `${getEnv("REACT_APP_AUTHSERVER_URL")}/login`;
  const responseType = `response_type=code`;
  const redirect = `redirect_uri=${getEnv("REACT_APP_REDIRECT_URI")}`;
  const clientId = `client_id=${getEnv("REACT_APP_FACET_CLIENT_ID")}`;

  useEffect(() => {
    /**
     * What happens if a logged-in user visits the `/login` endpoint?
     *
     * The user should be automatically logged out, i.e., by removing the access token from session storage
     * and forcing a re-render of the App component (via `removeAuthInfoh`).
     */
    localStorage.removeItem("accessToken");
    localStorage.removeItem("idToken");
    dispatch(removeAuthInfo());
  }, [dispatch]);

  return (
    <BasePage>
      <div className={styles.login}>
        <Image
          className={styles.logo}
          src={brighthiveFacetLogo}
          alt="Brighthive Logo"
          fluid
        />
        <a
          href={`${authURI}?${responseType}&${redirect}&${clientId}`}
          className="mt-5 btn btn-primary btn-lg"
        >
          Log-in with Brighthive
        </a>
      </div>
    </BasePage>
  );
};

export default Login;
