import React, { useState } from "react";
import { Dropdown, Form, Button } from "react-bootstrap";
import { ReactComponent as FilterIcon } from "assets/filter-icon.svg";
import { ReactComponent as ActiveFilterIcon } from "assets/active-filter-icon.svg";
import styles from "./MultiSelectFilter.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "presentational-components";

const MultiSelectFilter = ({ options, onFilter, column }) => {
  const [show, setShow] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [savedFilterValues, setSavedFilterValues] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const onClickSave = () => {
    setSavedFilterValues(selectedValues);
    onFilter(selectedValues);
    setShow(false);
  };

  const onClickCancel = () => {
    setSelectedValues(savedFilterValues);
    setShow(false);
  };

  const onClickCheckbox = (value) => {
    if (selectedValues.includes(value)) {
      const newValues = selectedValues.filter((curr) => curr !== value);
      setSelectedValues(newValues);
    } else {
      const newValues = selectedValues.concat([value]);
      setSelectedValues(newValues);
    }
  };

  const selectAllFilters = () => {
    setSelectedValues(options);
  };

  const clearAllFilters = () => {
    setSelectedValues([]);
  };

  const filterActive = savedFilterValues.length > 0;

  const visibleOptions = options.filter((option) => {
    if (typeof option === "string" || typeof option === "number") {
      return option
        .toString()
        .toLowerCase()
        .includes(searchValue.toLowerCase());
    } else {
      return false;
    }
  });

  return (
    <Dropdown
      show={show}
      className="position-static"
      onClick={(e) => e.stopPropagation()}
    >
      <Dropdown.Toggle
        onClick={() => setShow(!show)}
        as={filterActive ? ActiveFilterIcon : FilterIcon}
      />

      <Dropdown.Menu className={styles.dropdown}>
        <div className="d-flex mb-3">
          <Button
            className={`${styles.selectAllButton} ${styles.secondaryButton}`}
            onClick={selectAllFilters}
            variant="outline-primary"
          >
            Select All
          </Button>
          <Button
            className={styles.secondaryButton}
            onClick={clearAllFilters}
            variant="outline-primary"
          >
            Clear
          </Button>
        </div>
        <Form>
          <div className="d-flex mb-4">
            <Form.Control
              onChange={(e) => setSearchValue(e.target.value)}
              className={styles.search}
              size="lg"
              type="text"
            />
            <IconButton
              className={styles.searchIcon}
              disableHover
              ariaLabel="Close Modal"
              icon={<FontAwesomeIcon className="fa-md" icon={faSearch} />}
            />
          </div>
          <div className={`${styles.checkboxes} mb-4`}>
            {visibleOptions.map((optionString) => (
              <Form.Check
                key={optionString}
                className={styles.check}
                size={10}
                checked={selectedValues.includes(optionString)}
                onClick={() => onClickCheckbox(optionString)}
                type="checkbox"
                label={optionString}
              />
            ))}
          </div>
        </Form>
        <hr />
        <div className="d-flex justify-content-end">
          <Button
            className={styles.multiSelectButton}
            onClick={onClickCancel}
            variant="outline-primary"
          >
            Cancel
          </Button>
          <Button
            className={styles.multiSelectButton}
            onClick={onClickSave}
            variant="primary"
          >
            OK
          </Button>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MultiSelectFilter;
