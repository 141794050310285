import React, { useState, useRef } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

import { useClickOutside } from "utils/hooks/EventHooks";

import ChevronDownIcon from "../assets/chevron-down-icon.svg";
import styles from "./TopNavOption.module.scss";

interface Props {
  /**
   * The label of the menu option. Will always show instead of what's selected.
   */
  label?: string;
  /**
   * The menu options in the dropdown. These are React Router Links that will navigate users to the path provided in the 'to' field
   */
  options?: {
    label: string;
    to?: string;
    value?: any;
    onClick?: Function;
  }[];
  /**
   * Whether the page is a subset of the main menu option or not
   */
  selected?: boolean;
  className?: string;

  /**
   * width of the button, used to control width of the bottom selected border
   */
  width: number;

  /**
   * To render a different button, like for the profile avatar and admin icon. Passed the function to open the menu
   */
  renderButton?: (
    openMenu: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  ) => JSX.Element;

  /**
   * to make dropdown menu align right. for profile and admin menus mainly
   */
  rightAlignMenu?: boolean;

  /**
   * to add borders in between menu options
   */
  withBorders?: boolean;

  /**
   * if just rendering a Link
   */
  to?: string;
}

/**
 * Menu option in the top nav bar with dropdown of pages that users can navigate to.
 * Use width prop to control button width, which is used to set widths of everything else
 * The width has to be controlled because the button hover bold font will increase the width of the container if width is fit to content
 */

const TopNavOption = ({
  label,
  options,
  selected,
  className,
  width,
  renderButton,
  rightAlignMenu,
  withBorders,
  to,
}: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const closeMenu = () => {
    setIsMenuOpen(false);
  };
  const openMenu = (): void => {
    setIsMenuOpen(true);
  };

  useClickOutside(closeMenu, menuRef.current);

  const renderNavOption = () => {
    const optionClassName = classNames(
      styles.dropdownButton,
      selected && styles.dropdownButtonSelected
    );

    return renderButton ? (
      renderButton(openMenu)
    ) : options ? (
      <button
        onClick={openMenu}
        className={optionClassName}
        style={{
          width,
        }}
      >
        <span>{label}</span>
        <img
          src={ChevronDownIcon}
          className={styles.dropdownIndicator}
          alt="dropdown icon"
        />
      </button>
    ) : (
      /* @ts-ignore */
      <Link to={to} className={optionClassName}>
        {label}
      </Link>
    );
  };

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.buttonPositioner}>{renderNavOption()}</div>
      {selected && (
        <div
          className={styles.selectedBorder}
          style={{ width: width - 16, marginLeft: 8 }}
        />
      )}
      <div
        ref={menuRef}
        className={classNames(
          styles.dropdownMenu,
          isMenuOpen && styles.dropdownMenuOpen,
          rightAlignMenu && styles.dropdownMenuRight
        )}
        style={{ minWidth: width }}
        data-testid="menu"
      >
        {options &&
          options.map(({ to, onClick, label }, index) => {
            const handleClick = (e) => {
              if (onClick) onClick(e);

              closeMenu();
            };
            const props = {
              className: classNames(styles.menuOption, {
                [styles.menuOptionBorder]:
                  withBorders && index !== options.length - 1,
              }),
              onClick: handleClick,
              key: index,
            };
            return to ? (
              <Link {...props} to={to}>
                {label}
              </Link>
            ) : (
              <button {...props}>{label}</button>
            );
          })}
      </div>
    </div>
  );
};

export default TopNavOption;
