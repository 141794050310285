import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";

import { BasePage } from "presentational-components";
import Message from "./Message";

const PageNotFound = (props) => {
  let pageNotFound;

  if (props.authenticatedUserFromStore) {
    pageNotFound = (
      <Row className="mt-xl ml-5 text-blue">
        <Col xs={{ span: 11 }}>
          <Message>
            <Link to="/profile">
              <FontAwesomeIcon className="mr-2" icon={faArrowAltCircleRight} />
              Go to your profile page.
            </Link>
          </Message>
        </Col>
      </Row>
    );
  } else {
    pageNotFound = (
      <BasePage className="text-blue">
        <Container className="h-100 d-flex flex-column justify-content-center">
          <Row>
            <Col xs={{ span: 10, offset: 1 }}>
              <Message>
                <Link to="/login">
                  <FontAwesomeIcon
                    className="mr-2"
                    icon={faArrowAltCircleRight}
                  />
                  Go to the login page.
                </Link>
              </Message>
            </Col>
          </Row>
        </Container>
      </BasePage>
    );
  }

  return pageNotFound;
};

const mapStateToProps = (state) => {
  return {
    authenticatedUserFromStore: state.authServerReducer.authenticatedUser,
  };
};

export default connect(mapStateToProps)(PageNotFound);
