import React from "react";

import styles from "./Badge.module.scss";
import classNames from "classnames";
import { OWNER_TYPE } from "constants/organizationTypes";

interface Props {
  value: OWNER_TYPE;
  className?: string;
}

const Badge = ({ value, className }: Props) => {
  return (
    <span
      className={classNames(className, styles.common, {
        [styles.orangeTheme]: value === OWNER_TYPE.Collaboration,
        [styles.purpleTheme]: value === OWNER_TYPE.ThirdParty,
        [styles.blueTheme]: value === OWNER_TYPE.Member,
        [styles.magentaTheme]: value === OWNER_TYPE.MyOrganization,
      })}
    >
      {value}
    </span>
  );
};

Badge.defaultProps = {
  className: "",
};

export default Badge;
