import classnames from "classnames";
import React from "react";
import {
  AWAITING_DATA,
  PROCESSING,
  READY,
  REQUESTED,
  SHARED,
  STAGED,
  PENDING,
} from "./constants";
import styles from "./common.module.scss";

export const statusFormatter = (cell, { status }) => {
  return (
    <>
      <div
        className={classnames(styles.circle, {
          [styles.yellow]:
            status === STAGED || status === PROCESSING || status === PENDING,
          [styles.green]: status === SHARED || status === READY,
          [styles.red]: status === REQUESTED || status === AWAITING_DATA,
        })}
      />
      <span className={styles.statusText}>
        {status === SHARED ? "Uploaded" : status}
      </span>
    </>
  );
};
