import React from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Button, Modal } from "react-bootstrap";
import { ReactComponent as UnshareIcon } from "assets/unshare-icon.svg";
import { CircleIconButton } from "presentational-components";
import { useModal } from "utils/hooks/ModalHooks";
import styles from "../common.module.scss";

const StopSharingButton = ({ disabled, className, onClick }) => {
  const [modalOpen, openModal, closeModal] = useModal();

  const onClickStopSharing = () => {
    onClick();
    closeModal();
  };

  return (
    <>
      <CircleIconButton
        className={className}
        icon={<UnshareIcon />}
        onClick={openModal}
        disabled={disabled}
      />
      <Modal
        contentClassName={styles.modal}
        show={modalOpen}
        onHide={closeModal}
      >
        <Modal.Header>Unshare Data?</Modal.Header>
        <Modal.Body>
          You’re about to remove this resource from any projects it’s being
          contributed to.
        </Modal.Body>
        <div className="d-flex justify-content-end">
          <Button
            className={styles.cancelButton}
            onClick={closeModal}
            size="lg"
            variant="outline-primary"
          >
            Cancel
          </Button>
          <Button onClick={onClickStopSharing} size="lg" variant="primary">
            Unshare
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default StopSharingButton;
