import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import { PageNavBar, Dropdown } from "presentational-components";
import styles from "./Header.module.scss";

/**
 * Header component. Optionally render a button, a back link, and/or a Nav bar.
 */
const Header = ({
  header,
  badge,
  status,
  modalType,
  navOptions,
  backLinkPath,
  backLinkLabel,
  subheader,
  onHeaderButtonClick,
  headerButtonLabel,
  dropdownOptions,
  children,
  withBorder,
}) => {
  const renderSubheader = () => {
    if (!subheader) {
      return null;
    }

    if (typeof subheader === "string") {
      return (
        <p data-testid="subheader-p" className={styles.subheader}>
          {subheader}
        </p>
      );
    }

    return subheader;
  };

  return (
    <header
      className={classNames(styles.header, {
        [styles.headerWithBorder]: withBorder,
      })}
    >
      <div
        className={classNames(styles.headerFirstRow, {
          [styles.headerFirstRowWithNav]: !backLinkPath && navOptions,
          [styles.headerFirstRowWithBackLink]: backLinkPath,
        })}
      >
        <div className={styles.headerTexts}>
          <div className={styles.mainHeaderContents}>
            <h2 className={styles.headerTitle}>{header}</h2>
            <h2>{badge}</h2>
            {status && <div className={styles.headerStatus}>{status}</div>}
          </div>
          {renderSubheader()}
        </div>
        {onHeaderButtonClick && (
          <Button
            className={classNames("btn-lg", styles.headerButton)}
            variant="primary"
            onClick={onHeaderButtonClick}
          >
            {headerButtonLabel}
          </Button>
        )}
        {dropdownOptions && (
          <Dropdown
            label="Create New"
            options={dropdownOptions}
            buttonClassName={styles.dropdown}
          />
        )}
      </div>
      {backLinkPath && (
        <Link className={styles.backLink} to={backLinkPath}>
          <FontAwesomeIcon
            className={styles.backLinkIcon}
            icon={faChevronLeft}
          />
          {backLinkLabel}
        </Link>
      )}
      {navOptions && <PageNavBar options={navOptions} />}
      {children}
    </header>
  );
};

Header.propTypes = {
  /**
   * Header of page
   */

  header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * An element (such as a Badge component) that labels the header with additional information
   */
  badge: PropTypes.element,
  /**
   * Subheader of page. Can be text or an element, such as InlineEditInput
   */
  subheader: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Status tag if available (i.e. data resource status).
   */
  status: PropTypes.element,
  /**
   * Label of button to open modal
   */
  headerButtonLabel: PropTypes.string,
  /**
   * Click handler for header button. Passing this in will render the button
   */
  onHeaderButtonClick: PropTypes.func,
  /**
   * Type of modal
   */
  modalType: PropTypes.string,
  /**
   * Array of nav options. Passing it in will render the nav bar
   */
  navOptions: PropTypes.array,
  /**
   * Path that the back link will take the user to. Passing it in will render the link.
   */
  backLinkPath: PropTypes.string,
  /**
   * Label for the back link
   */
  backLinkLabel: PropTypes.string,
  /**
   * Children props to render
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  /**
   * Control showing bottom border
   */
  withBorder: PropTypes.bool,
};

Header.defaultProps = {
  backLinkLabel: "Back",
};

export default Header;
