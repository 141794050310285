import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  fromPromise,
  concat,
  gql,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import getEnv from "utils/Envs";
import { getJwtToken } from "utils/Axios";
import { setContext } from "@apollo/client/link/context";
import { toast } from "react-toastify";
import axios from "axios";
import constants from "./constants/ErrorMessages";
import { setAuthenticatedInfoAndSaveToLocalStorage } from "./store/AuthServer/actionCreators";

export const DOWNLOAD_API_CONTEXT_NAME = "download-api";
export const META_DATA_API_CONTEXT_NAME = "meta-data-api";
export const PERMISSIONS_API = "permissions-api";

let isRefreshing = false;
let pendingRequests = [];

const AppApolloProvider = (props) => {
  const userInfo = useSelector(
    (state) => state.authServerReducer.authenticatedUser
  );
  const [apolloClient, setApolloClient] = useState(null);
  const dispatch = useDispatch();

  const handleError = () => {
    toast.error(constants.DEFAULT_ERROR_MESSAGE);
  };

  const getRefreshToken = () => {
    const authData = new URLSearchParams();
    authData.append("grant_type", "refresh_token");
    authData.append("client_id", `${getEnv("REACT_APP_FACET_CLIENT_ID")}`);
    authData.append(
      "refresh_token",
      localStorage.getItem("refreshToken") || ""
    );
    return axios
      .post(`${getEnv("REACT_APP_AUTHSERVER_URL")}/oauth2/token`, authData)
      .then((response) => {
        const { access_token, id_token } = response.data;
        return { access_token, id_token };
      });
  };

  const resolvePendingRequests = () => {
    pendingRequests.map((callback) => callback());
    pendingRequests = [];
  };

  const errorLink = onError(({ forward, operation }) => {
    if (!operation.variables?.__bypassToast__) {
      console.error(operation);
      // toast.error(constants.DEFAULT_ERROR_MESSAGE);
    }
    forward(operation);
  });

  const errorLinkRefreshToken = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      const unautheticatedErrorExists = graphQLErrors?.some(
        (err) => err?.extensions?.code === "UNAUTHENTICATED"
      );
      if (graphQLErrors && unautheticatedErrorExists) {
        // error code is set to UNAUTHENTICATED
        // when AuthenticationError thrown in resolver
        let forward$;

        if (!isRefreshing) {
          isRefreshing = true;
          forward$ = fromPromise(
            getRefreshToken()
              .then(({ access_token, id_token }) => {
                // Store the new tokens for your auth link
                console.log("REFRESHING TOKENS");
                dispatch(
                  setAuthenticatedInfoAndSaveToLocalStorage(
                    access_token,
                    id_token,
                    handleError
                  )
                );
                resolvePendingRequests();
                return access_token;
              })
              .catch(() => {
                pendingRequests = [];
                // Handle token refresh errors e.g clear stored tokens, redirect to login, ...
                localStorage.setItem("accessToken", "");
                localStorage.setItem("idToken", "");
                handleError();
                return;
              })
              .finally(() => {
                isRefreshing = false;
              })
          ).filter((value) => Boolean(value));
        } else {
          // Will only emit once the Promise is resolved
          forward$ = fromPromise(
            new Promise((resolve) => {
              pendingRequests.push(() => resolve());
            })
          );
        }

        return forward$.flatMap(() => forward(operation));
      }
    }
  );

  const apolloConfigurationKnowledgeGraphApi = {
    uri: `${getEnv("REACT_APP_DATA_API_URL")}/graphql`,
    cache: new InMemoryCache(),
    headers: {
      "content-type": "application/json",
    },
    onError: errorLink,
  };

  const authLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: `${getJwtToken()}`,
      },
    };
  });

  const apolloConfigurationDownloadApi = {
    uri: `${getEnv("REACT_APP_DOWNLOADS_API_URL")}/graphql`,
    cache: new InMemoryCache(),
    headers: {
      "content-type": "application/json",
      "x-api-key": getEnv("REACT_APP_DOWNLOADS_API_KEY"),
    },
    onError: errorLink,
  };

  const apolloConfigurationPermissionsApi = {
    uri: `${getEnv("REACT_APP_PERMISSIONS_API_URL")}/graphql`,
    cache: new InMemoryCache(),
    headers: {
      "content-type": "application/json",
    },
    onError: errorLink,
  };

  const apolloConfigurationMetaDataApi = {
    uri: `${getEnv("REACT_APP_META_DATA_V2_API_URL")}/graphql`,
    cache: new InMemoryCache(),
    headers: {
      "content-type": "application/json",
      "x-api-key": getEnv("REACT_APP_META_DATA_V2_API_KEY"),
    },
    onError: errorLink,
  };

  const httpLinkKnowledgeGraphApi = new HttpLink({
    ...apolloConfigurationKnowledgeGraphApi,
  });

  const httpLinkDownloadApi = new HttpLink({
    ...apolloConfigurationDownloadApi,
  });

  const httpLinkMetaDataApi = new HttpLink({
    ...apolloConfigurationMetaDataApi,
  });

  const httpLinkPermissionsApi = new HttpLink({
    ...apolloConfigurationPermissionsApi,
  });

  const knowledgeGraphLinkWithAuth = concat(
    errorLinkRefreshToken,
    concat(authLink, httpLinkKnowledgeGraphApi)
  );

  const permissionsLinkWithAuth = concat(
    errorLinkRefreshToken,
    concat(authLink, httpLinkPermissionsApi)
  );

  const linkSplit2 = ApolloLink.split(
    (operation) => operation.getContext().clientName === PERMISSIONS_API, // Routes the query to the proper client,
    permissionsLinkWithAuth,
    knowledgeGraphLinkWithAuth
  );

  const linkSplit1 = ApolloLink.split(
    (operation) =>
      operation.getContext().clientName === META_DATA_API_CONTEXT_NAME, // Routes the query to the proper client
    httpLinkMetaDataApi,
    linkSplit2
  );

  useEffect(() => {
    const client = new ApolloClient({
      ...apolloConfigurationKnowledgeGraphApi,
      link: ApolloLink.split(
        (operation) =>
          operation.getContext().clientName === DOWNLOAD_API_CONTEXT_NAME,
        // the string "download-api" can be anything you want,
        httpLinkDownloadApi, // <= apollo will send to this if clientName is "download-api"
        linkSplit1 // <= otherwise will send to this
      ),
      typeDefs: gql`
        enum RoleType {
          COLLABORATION_ADMIN
        }
      `,
    });

    setApolloClient(client);
    // eslint-disable-next-line
  }, [userInfo]);

  return apolloClient ? (
    <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>
  ) : null;
};

export default AppApolloProvider;
