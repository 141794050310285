export const errorObj = (errorObj) => {
  /**
   * This action creator dispatches the Axios error object.
   *
   * What's that? Axios rejects all responses that do not match 2xx
   * and returns an error object with the following properties:
   * "config", "request", "response", "isAxiosError", "toJSON".
   * Notably, the "response" property returns essential data about the request error, including
   * the `status` and `statusText`.
   */
  return {
    type: "SHOW_ERROR",
    errorObj: errorObj,
  };
};

export const updateErrorObj = (errorToRemove) => {
  return {
    type: "UPDATE_ERROR",
    errorToRemove: errorToRemove,
  };
};
