import { Validator } from "jsonschema";

export const convertSchemaToFields = (schema) => {
  const properties = schema?.properties;
  if (typeof properties === "object") {
    return Object.getOwnPropertyNames(properties).map((key) => {
      const property = properties[key];
      const validators = [];
      if (typeof Array.isArray(schema?.required)) {
        if (schema.required.includes(key)) {
          validators.push({ validate: "required" });
        }
      }
      if (property.uniqueItems) {
        validators.push({ validate: "unique" });
      }
      return {
        key,
        label: property.label || key,
        description: property.description,
        validators,
      };
    });
  }
  return [];
};

const mockSchema = {
  $schema: "https://json-schema.org/draft/2020-12/schema",
  $id: "https://example.com/product.schema.json",
  title: "Student_Enrollment",
  description: "Student Enrollment Description",
  type: "object",
  properties: {
    institution_id: {
      description: "Your institution's ID. ",
      type: "integer",
      minimum: 8,
      maximum: 8,
    },
    special_program_flag: {
      description:
        "Identify all students participating in the CCGEF program by adding \u201cccgefcohort\u201d in this field. If you have students participating in a specific program, please provide this information in this field",
      type: "string",
      minLength: 11,
      maxLength: 24,
    },
    cohort: {
      description:
        "Academic year in which the student first enrolled in at least one course. Must match the Cohort value in the course data file. Must be 2 consecutive years between 2000 and today Example: 2019-20",
      type: "string",
      pattern: "^[0-9]{4}-[0-9]{2}$",
    },
    cohort_term: {
      description:
        "Term in which student first enrolled in at least one course. Must match the Cohort Term value in the course data file. ",
      enum: ["Fall", "Winter", "Spring", "Summer"],
    },
    ssn: {
      description:
        "For CCGEF, provide both the Student ID and SSN. Student's social security number.",
      anyOf: [
        {
          type: "integer",
          minimum: 9,
          maximum: 9,
        },
        {
          enum: ["UK"],
        },
      ],
    },
    student_id: {
      description:
        "For CCGEF, provide both the Student ID and SSN. Your institution's ID number for the student. Cannot be the same as SSN or ITIN. Can include: period, apostrophe, hyphen, underscore.",
      type: "string",
      minLength: 3,
      maxLength: 20,
    },
    first_name: {
      description: "Student's first name.",
      type: "string",
      minLength: 1,
      maxLength: 60,
    },
    middle_name: {
      description: "Student's middle name",
      anyOf: [
        {
          type: "string",
          minLength: 1,
          maxLength: 60,
        },
        {
          enum: ["UK", "NA"],
        },
      ],
    },
    last_name: {
      description: "Student's last name.",
      type: "string",
      minLength: 1,
      maxLength: 60,
    },
    dob: {
      description: "Student's date of birth. ",
      type: "string",
      pattern: "^[0-9]{4}(0[0-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])$",
    },
    ethnicity: {
      description: "Student's ethnicity.",
      enum: ["H", "N", "UK"],
    },
    race: {
      description:
        "Student's race. Can include multiple values separated by pipe symbols (|) in any order. For example, a student who is Asian, White, and a non-resident alien could be listed as: AN|W|A.",
      type: "string",
      minLength: 1,
      maxLength: 60,
    },
    veteran_status: {
      description: "Provide military status at the time of enrollment.",
      enum: [0, 1, 2, 3, "UK"],
    },
    disability_status: {
      description: "Disability status of student with the",
      enum: ["Y", "N", "UK"],
    },
    gender: {
      description: "Student's gender.",
      enum: ["M", "F", "N", "P", "UK"],
    },
    marital_status: {
      description: "Student's marital status at the time of enrollment.",
      enum: [1, 2, 3, 4, 5, "UK"],
    },
    parenting: {
      description: "Parenting status at the time of enrollment.",
      enum: ["Y", "N", "UK"],
    },
    hs_completion_year: {
      description:
        "Date student earned their high school diploma or equivalency. Must be a year between 1900 and today.",
      anyOf: [
        {
          type: "string",
          pattern: "^[0-9]{4}$",
        },
        {
          enum: ["UK", "NA"],
        },
      ],
    },
    employment_status: {
      description: "Employment status at the time of enrollment.",
      enum: [1, 2, 3, 4, "UK"],
    },
    first_gen: {
      description:
        "Indicates whether either of the student's parents has completed a certificate or higher credential at a post-secondary institution. This field is used to populate the First Generation dashboard filter. If this field is populated, you can filter students by first generation  status in the dashboards.",
      type: "string",
    },
    dual_summer: {
      description:
        "Student was a previous dual enrollment student and/or enrolled in summer work before their first term enrolled with credential-seeking status",
      type: "string",
    },
    enrollment_type: {
      description: "Student's cohort enrollment type.",
      type: "string",
    },
    number_of_college_credits_attempted_to_transfer: {
      description:
        "Number of credits the student attempted to transfer from another institution (whether or not the credits were accepted).",
      anyOf: [
        {
          type: "number",
        },
        {
          enum: [0, "NA"],
        },
      ],
    },
    number_of_college_transfer_credits_accepted: {
      description:
        "Number of transfer credits your institution accepted for the student.",
      anyOf: [
        {
          type: "number",
        },
        {
          enum: ["NA"],
        },
      ],
    },
    reading_replacement: {
      description:
        "Student was college ready in Reading upon enrollment based on your institution's Reading placement policies.",
      enum: ["C", "N", "UK", "NA"],
    },
    math_placement: {
      description: "Student was college ready in math upon ",
      enum: ["C", "N", "UK", "NA"],
    },
    english_placement: {
      description:
        "Student was college ready in English upon enrollment based on the institution's English placement policies",
      enum: ["C", "N", "UK", "NA"],
    },
    gateway_math_status: {
      description:
        "Whether the student is required to complete a gateway math course at enrollment. ",
      enum: ["R", "N", "UK", "NA"],
    },
    gateway_english_status: {
      description:
        "Whether the student is required to complete a gateway English course at enrollment. ",
      enum: ["R", "N", "UK", "NA"],
    },
    current_county: {
      description: "Student's current country of residence.",
      type: "string",
      minLength: 1,
    },
    student_phone_number: {
      description: "Student's phone number.",
      type: "string",
      minLength: 1,
      maxLength: 20,
    },
    student_email: {
      description: "Student's email address.",
      anyOf: [
        {
          enum: ["UK"],
        },
      ],
    },
    pell_recipent: {
      description: "Whether the student received a Pell grant during the term",
      enum: ["Y", "N", "UK"],
    },
    institutional_grant: {
      description:
        "Total amount of other institutional grants (other than needs-based, employer aid, merit aid, or military/veteran grants) the student received during the academic year.",
      anyOf: [
        {
          type: "integer",
          minimum: 0,
        },
        {
          enum: ["NA"],
        },
      ],
    },
    ccgef_sponsorship: {
      description:
        "Total amount of CCGEF scholarship funds received by the student",
      anyOf: [
        {
          type: "integer",
        },
        {
          enum: ["UK", "NA"],
        },
      ],
    },
    "non-credit_course": {
      description: "Is this course a non-credit course? ",
      enum: ["Y", "N"],
    },
    course_ctid: {
      description:
        "The CTID for the course.  All courses published to the Credential Registry have a CTID.  The Credential Engine team will provide the course CTIDs to each college. ",
      anyOf: [
        {
          type: "string",
        },
        {
          enum: ["UK"],
        },
      ],
    },
    course_prefix: {
      description: "Course prefix as it appears in your institution's catalog",
      type: "string",
      minLength: 1,
      maxLength: 50,
    },
    course_number: {
      description: "Course number as it appears in your institution's catalog.",
      type: "string",
      minLength: 1,
      maxLength: 20,
    },
    course_name: {
      description: "Course name as it appears in your institution's catalog.",
      type: "string",
      minLength: 1,
      maxLength: 50,
    },
    course_cip: {
      description:
        "Use Standard 6-digit CIP Format: 99.9999  See https://nces.ed.gov/ipeds/cipcode for a list of valid codes. If not available, enter Missing. We accept both the 2010 and 2020 CIP codes.",
      anyOf: [
        {
          type: "string",
        },
        {
          enum: ["Missing", "missing"],
        },
      ],
    },
    course_begin_date: {
      description: "Date the course began. Year must be between 1900 and today",
      type: "string",
      pattern: "^[0-9]{4}(0[0-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])$",
    },
    course_end_date: {
      description:
        "Date the course is scheduled to end. Year must be after 1900.",
      type: "string",
      pattern: "^[0-9]{4}(0[0-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])$",
    },
  },
  required: [
    "institution_id",
    "special_program_flag",
    "cohort",
    "cohort_term",
    "ssn",
    "student_id",
    "first_name",
    "middle_name",
    "last_name",
    "dob",
    "ethnicity",
    "race",
    "veteran_status",
    "disability_status",
    "gender",
    "marital_status",
    "parenting",
    "hs_completion_year",
    "employment_status",
    "first_gen",
    "dual_summer",
    "enrollment_type",
    "number_of_college_credits_attempted_to_transfer",
    "number_of_college_transfer_credits_accepted",
    "reading_replacement",
    "math_placement",
    "english_placement",
    "gateway_math_status",
    "gateway_english_status",
    "current_county",
    "student_phone_number",
    "student_email",
    "pell_recipent",
    "institutional_grant",
    "ccgef_sponsorship",
    "non-credit_course",
    "course_ctid",
    "course_prefix",
    "course_number",
    "course_name",
    "course_cip",
    "course_begin_date",
    "course_end_date",
  ],
};

export const getMockSchema = () =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(mockSchema);
    }, 1000);
  });

const validator = new Validator();

const validateValue = (value, property) => {
  let valueToValidate = value;
  const propertyIsNumberOrInteger =
    property.type === "number" || property.type === "integer";
  const anyOfContainsNumberOrInteger =
    Array.isArray(property.anyOf) &&
    property.anyOf.some(
      (prop) => prop?.type === "number" || prop?.type === "integer"
    ) &&
    !isNaN(value);
  const enumContainsNumericValue =
    Array.isArray(property.enum) &&
    property.enum.some((currEnumValue) => !isNaN(currEnumValue)) &&
    !isNaN(value);

  if (
    propertyIsNumberOrInteger ||
    anyOfContainsNumberOrInteger ||
    enumContainsNumericValue
  ) {
    valueToValidate = Number(value);
  }
  return validator.validate(valueToValidate, property, { nestedErrors: true });
};

export const generateFieldHooks = (schema) => {
  const fieldsHooks = {};
  const properties = schema?.properties;
  if (typeof properties === "object") {
    Object.getOwnPropertyNames(properties).forEach((key) => {
      const property = properties[key];
      fieldsHooks[key] = (values) => {
        let changeValues = [];
        values.forEach(([value, index]) => {
          const validationResult = validateValue(value, property);
          if (
            Array.isArray(validationResult.errors) &&
            validationResult.errors.length > 0
          ) {
            const message = validationResult.errors
              .map((error) => error.message)
              .join(", ");
            changeValues.push([
              {
                info: [
                  {
                    message,
                    level: "error",
                  },
                ],
              },
              index,
            ]);
          }
        });
        return changeValues;
      };
    });
  }
  return fieldsHooks;
};

export const generateOnRecordChange = (schema) => {
  let onRecordChange = null;
  const properties = schema?.properties;
  if (typeof properties === "object") {
    onRecordChange = (record, index) => {
      const onRecordChangeObject = {};
      Object.getOwnPropertyNames(properties).forEach((key) => {
        const property = properties[key];
        if (record && record[key]) {
          const validationResult = validateValue(record[key], property);
          if (
            Array.isArray(validationResult.errors) &&
            validationResult.errors.length > 0
          ) {
            const message = validationResult.errors
              .map((error) => error.message)
              .join(", ");
            onRecordChangeObject[key] = {
              info: [
                {
                  message,
                  level: "error",
                },
              ],
            };
          }
        }
      });
      return onRecordChangeObject;
    };
  }
  return onRecordChange;
};
