import React, { useContext } from "react";
import { Nav } from "react-bootstrap";
import classnames from "classnames";
import NavItem from "../NavItem/NavItem";
import collapseIcon from "assets/collapse-icon.svg";
import { IconButton } from "presentational-components";
import styles from "./SideNavBar.module.scss";
import { CollabContext } from "../CollabContext";
import { useQuery } from "@apollo/client";
import { PERMISSIONS_API } from "AppApolloProvider";
import { VERIFY_ROLE, COLLABORATION_ADMIN } from "graphql/auth";

const SideNavBar = ({ sideNavExpanded, setSideNavExpanded }) => {
  const collabContext = useContext(CollabContext);
  const currentCollaborationId = collabContext?.currentCollaborationId;

  const { data: roleData } = useQuery(VERIFY_ROLE, {
    variables: {
      roleType: COLLABORATION_ADMIN,
      resourceUuid: currentCollaborationId,
    },
    context: {
      clientName: PERMISSIONS_API,
    },
    skip: !currentCollaborationId,
  });

  const navItems = () => {
    return (
      <>
        <NavItem className={styles.nestedNavItem} exact path="/" label="Home" />
        <NavItem
          className={styles.nestedNavItem}
          path="/data/my-data"
          label="My Data"
        />
        {roleData && roleData?.verifyRole?.success && (
          <NavItem
            className={styles.nestedNavItem}
            path="/data/shared-data"
            label="Shared Data"
          />
        )}
      </>
    );
  };

  return (
    <Nav expanded={sideNavExpanded} defaultActiveKey="/login">
      <nav
        className={classnames(styles.nav, {
          [styles.navExpanded]: sideNavExpanded,
          [styles.navCollapsed]: !sideNavExpanded,
        })}
        aria-label="secondary-nav"
      >
        <div className={styles.sideNavHeader}>
          <IconButton
            ariaLabel="collapse side navigation"
            className={styles.collapseIcon}
            onClick={() => setSideNavExpanded(false)}
            src={collapseIcon}
            alt="collapse-side-nav"
          />
        </div>
        {navItems()}
      </nav>
    </Nav>
  );
};

export default SideNavBar;
