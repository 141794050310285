import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "../";

import styles from "./Drawer.module.scss";

interface Props {
  inline: boolean;
  children: React.ReactElement;
  width: number;
  className?: string;
  expanded: boolean;
  id?: string;
}

/**
 *
 * Drawer component that can be displayed inline or on top of content. Make sure to position the parent if you're not displaying the Drawer inline.
 */

const Drawer = ({
  id,
  inline,
  children,
  width,
  className,
  expanded: expandedProp,
}: Props) => {
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    setExpanded(expandedProp);
  }, [expandedProp]);
  const toggleExpanded = () => setExpanded(!expanded);
  const expandIcon = expanded ? faChevronLeft : faChevronRight;

  return (
    <div
      id={id}
      className={classNames(styles.drawer, className)}
      style={{
        position: inline ? "relative" : "absolute",
        width: expanded ? width : 15,
      }}
    >
      <IconButton
        ariaLabel={`${expanded ? "collapse" : "expand"} drawer`}
        className={styles.expandButton}
        onClick={toggleExpanded}
        icon={<FontAwesomeIcon icon={expandIcon} />}
      />
      <div className={styles.contentHiderWrapper}>
        <div className={styles.contentWrapper} style={{ width }}>
          {children}
        </div>
      </div>
    </div>
  );
};

Drawer.defaultProps = {
  inline: false,
  width: 338,
  expanded: false,
};

export default Drawer;
