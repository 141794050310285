import { useMemo } from "react";

export const useFixedLayoutTable = (columns) => {
  return useMemo(() => {
    let totalWidth = 0;
    const columnsCopy = columns.map((column) => ({ ...column }));
    columnsCopy.forEach((column) => {
      if (column.width) {
        if (typeof column.width !== "number") {
          throw new Error("Column width property must be a number");
        }

        totalWidth += column.width;
      }
    });

    if (totalWidth) {
      columnsCopy.forEach((column) => {
        if (column.width) {
          column.width = `${(column.width * 100) / totalWidth}%`;
        }
      });
    }

    return {
      isFixedLayoutTable: Boolean(totalWidth),
      columns: columnsCopy,
    };
  }, [columns]);
};
