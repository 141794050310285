import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_COLLABORATIONS_FOR_DROPDOWN } from "graphql/data";
import { COLLABORATION_ADMIN } from "graphql/auth";
import { useSelector } from "react-redux";
import ProtectedRoute from "components/Navigation/ProtectedRoute";
import Login from "components/Authentication/Login/Login";
import AuthRedirect from "components/Authentication/AuthRedirect";
import Logout from "components/Authentication/Logout";
import NavContainer from "components/Navigation/NavContainer";
import { MyData, SharedData } from "components/Data";
import PageNotFound from "components/Errors/PageNotFound/PageNotFound";
import CollabOverview from "components/CollabOverview/CollabOverview";
import { Nav } from "./Nav";
import { SideNavContext } from "./SideNavContext";
import { CollabContext } from "./CollabContext";

const AllRoutes = () => {
  const [sideNavExpanded, setSideNavExpanded] = useState(true);
  const [dataOptionsExpanded, setDataOptionsExpanded] = useState(false);
  const [firstFetchCompleted, setFirstFetchCompleted] = useState(false);
  const [currentCollaborationId, setCurrentCollaborationId] = useState(null);

  const accessTokenInfo = useSelector(
    (state) => state.authServerReducer.accessTokenInfo
  );

  const { data: collaborationsData } = useQuery(
    GET_COLLABORATIONS_FOR_DROPDOWN,
    {
      skip: !accessTokenInfo,
      onCompleted: (data) => {
        if (!firstFetchCompleted) {
          if (
            Array.isArray(data?.collaborations) &&
            data.collaborations.length > 0
          ) {
            setCurrentCollaborationId(data.collaborations[0].uuid);
          }
          setFirstFetchCompleted(true);
        }
      },
    }
  );
  const collaborations = collaborationsData?.collaborations || [];

  return (
    <>
      <SideNavContext.Provider
        value={{
          sideNavExpanded,
          dataOptionsExpanded,
          setSideNavExpanded,
          setDataOptionsExpanded,
        }}
      >
        <CollabContext.Provider
          value={{
            collaborations,
            currentCollaborationId,
            setCurrentCollaborationId,
          }}
        >
          <Switch>
            <Route path="/login" exact component={Login} />
            <Route path="/auth/redirect" exact component={AuthRedirect} />
            <Route path="/logout" exact component={Logout} />
            <Route
              render={() => (
                <NavContainer nav={<Nav />}>
                  <Switch>
                    <ProtectedRoute
                      path="/"
                      exact
                      componentToRender={CollabOverview}
                    />
                    <ProtectedRoute
                      path="/data/my-data"
                      componentToRender={MyData}
                    />
                    <ProtectedRoute
                      path="/data/shared-data"
                      componentToRender={SharedData}
                      roleType={COLLABORATION_ADMIN}
                    />
                    <Route component={PageNotFound} />
                  </Switch>
                </NavContainer>
              )}
            />
            <Route component={PageNotFound} />
          </Switch>
        </CollabContext.Provider>
      </SideNavContext.Provider>
    </>
  );
};

export default AllRoutes;
