import React, { useContext } from "react";
import { withRouter } from "react-router";
import {
  TopNavOption,
  IconButton,
  ProfileAvatarIcon,
} from "presentational-components";
import expandIcon from "assets/expand-icon.svg";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { SideNavContext } from "../SideNavContext";
import styles from "./Nav.module.scss";
import { useQuery } from "@apollo/client";
import { GET_PERSON } from "graphql/data";
import brighthiveLogo from "assets/brighthive-logo-medium.svg";
import classnames from "classnames";

const Nav = () => {
  const { pathname } = useLocation();
  const idTokenInfo = useSelector(
    (state) => state.authServerReducer.idTokenInfo
  );

  const userUUID = idTokenInfo?.person_uuid;

  const { data: personData } = useQuery(GET_PERSON, {
    variables: { uuid: userUUID },
    skip: !userUUID,
  });

  const { sideNavExpanded, setSideNavExpanded } = useContext(SideNavContext);

  const profileOptions = [
    {
      label: "Log out",
      to: "/logout",
    },
  ];

  const profileButton = (openMenu) => {
    return (
      <button
        className={styles.roundedButton}
        onClick={openMenu}
        aria-label="profile"
      >
        <ProfileAvatarIcon
          firstname={personData?.person?.givenName}
          lastname={personData?.person?.familyName}
          email={personData?.person?.emailAddress}
          id={personData?.person?.uuid}
        />
      </button>
    );
  };

  const pageHeaderFromPath = (path: string) => {
    if (path === "/") {
      return "Home";
    } else if (path.includes("collaboration-map")) {
      return "Collaboration Map";
    } else if (path.includes("data/my-data")) {
      return "My Data";
    } else if (path.includes("data/requested-data")) {
      return "Requested Data";
    } else if (path.includes("data/shared-data")) {
      return "Shared Data";
    } else if (path.includes("data/combined-data")) {
      return "Combined Data";
    } else if (path.includes("data/templates")) {
      return "Templates";
    } else if (path.includes("people")) {
      return "People";
    } else if (path.includes("profile")) {
      return "Profile";
    } else {
      console.error(`Pathname has no page header mapping: ${path}`);
      return "";
    }
  };

  const summaryInfoFromPath = (path: string) => {
    if (path === "/") {
      return "Education Design Lab has established the Data Collaborative for a Skills-Based Economy (the “Collaborative”) to support various types of research and evaluation projects, including evaluations of the Community College Growth Engine Fund (“CCGEF”). Colleges will provide to the Collaborative two types of data relating to CCGEF micro-pathways: (1) student-level information and (2) information describing courses, credentials, competencies, and pathways (collectively referred to as micro-pathway program information).  Together, the student and micro-pathway program information will provide valuable insights into micro-pathway designs and student enrollments, demographics, outcomes, and insights. ";
    } else if (path.includes("data/my-data")) {
      return "This page shows the data assets you’ve been requested to upload to the CCGEF Data Collaborative. Click on the “Add Data” button for each of the data assets to access the data uploader. CLICK HERE for an uploader user guide. ";
    } else if (path.includes("data/shared-data")) {
      return "This page shows the requested and uploaded data assets for colleges participating in the CCGEF Collaboration Hub.";
    } else {
      console.error(`Pathname has no page summary mapping: ${path}`);
      return "";
    }
  };

  const pageHeader = (
    <div className={styles.headers}>
      <h1 className={styles.header_mainText}>{pageHeaderFromPath(pathname)}</h1>

      <span className={styles.header_helperText}>
        {summaryInfoFromPath(pathname)}
      </span>
    </div>
  );

  return (
    <>
      <nav className={styles.navContainer} aria-label="primary-nav">
        {!sideNavExpanded && (
          <div className={classnames("mr-3", styles.expandIcon)}>
            <IconButton
              ariaLabel="expand side navigation"
              className={styles.hamburger}
              onClick={() => setSideNavExpanded(true)}
              src={expandIcon}
              alt="expand-side-nav"
            />
          </div>
        )}

        <div className="flex-grow-1">
          <div className="d-flex justify-content-between mb-4">
            <div className="d-flex align-items-center">
              <img
                src={brighthiveLogo}
                alt="Brighthive Logo"
                className={styles.logo}
              />
              <div className={styles.betaText}>beta</div>
            </div>
            <div className="d-flex align-items-center">
              {/*<NavDropdown*/}
              {/*  className={styles.dropdown}*/}
              {/*  defaultLabel="Collaborations"*/}
              {/*  onSelectOption={(option) => setCurrentCollaborationId(option.value)}*/}
              {/*  options={formattedCollaborations}*/}
              {/*  selectedValue={currentCollaborationId}*/}
              {/*/>*/}
              <div className={styles.collabNameText}>
                CCGEF Data Collaboration
              </div>
              <TopNavOption
                className={styles.navOptionRightSection}
                renderButton={profileButton}
                options={profileOptions}
                rightAlignMenu
                withBorders
                width={145}
              />
            </div>
          </div>

          <div
            className={classnames(
              "d-flex justify-content-between",
              styles.bottomHeader
            )}
          >
            <div className={styles.title}>{pageHeader}</div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default withRouter(Nav);
