import { DateTime } from "luxon";

import { DATETIME_FORMAT } from "constants/Formats";

export const makeTimeStamp = (timeStamp, isEpoch = false) => {
  /**
   * Brighthive APIs return timestamps in two formats:
   * TIMESTAMP – str(datetime.utcnow()) – in which we know that the timestamp arrives in UTC.
   * EPOCH – int(time.time()) – in which we know the number of seconds that
   * have elapsed since January 1, 1970.
   */
  if (!timeStamp) return "Unavailable";

  let dateTime;

  if (!isEpoch) {
    dateTime = DateTime.fromISO(timeStamp, { zone: "utc" }).toLocal();
  } else {
    const unixTime =
      typeof timeStamp !== "number" ? parseInt(timeStamp, 10) : timeStamp;

    dateTime = DateTime.fromSeconds(unixTime);
  }

  return dateTime.toFormat(DATETIME_FORMAT);
};
